import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

// Translation for Form default values
export const TranslateSelect = (selectedLength: number) => {
  return {
    multipleSelected: useDsTranslateMessage({
      id: 'mcds.form.multiple_selected',
      defaultMessage: `${selectedLength} selected`,
      values: { selectedLength: selectedLength },
    }),
  };
};

/**
 * Checks if the specified flag is set on the window object.
 *
 * @param {string} flagName - The name of the flag to check.
 * @returns {boolean} True if the flag is set, false otherwise.
 */
export const mcdsFlagCheck = (flagName: string): boolean => {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  return Boolean((window as any)[flagName]).valueOf();
};

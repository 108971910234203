import React, { ReactNode, useLayoutEffect, useRef } from 'react';
import cx from 'classnames';

import { TickIcon } from '@mc/wink-icons';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Checkmark } from '@design-systems/icons';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import stylesheet from './Option.css';
import {
  CheckboxDefault,
  CheckboxChecked,
  CheckboxDisabled,
  CheckboxDisabledChecked,
} from './ListboxIcons';

export type OptionProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  id?: string;
  isFiltered?: boolean;
  isHighlighted?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  onHighlight?: (value: string | React.ReactNode) => void;
  value?: string | number;
  multiple?: boolean;
  hide?: boolean;
};

function Option({
  value,
  children,
  disabled = false,
  id,
  isFiltered = false,
  isSelected,
  isHighlighted = false,
  onClick,
  onHighlight,
  multiple,
  hide = false,
}: OptionProps) {
  const ref = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    if (isHighlighted && ref.current) {
      scrollIntoView(ref.current, {
        block: 'nearest',
        scrollMode: 'if-needed',
        boundary: ref.current?.parentNode as Element,
      });
    }
  }, [isHighlighted]);

  if (isFiltered) {
    return null;
  }

  const isRedesignFlagOn = mcdsFlagCheck(
    'xp_mcds_redesign_components_molecules',
  );

  let CheckboxIcon: ReactNode = null;
  if (disabled && isSelected) {
    CheckboxIcon = <CheckboxDisabledChecked />;
  } else if (isSelected) {
    CheckboxIcon = <CheckboxChecked />;
  } else if (disabled) {
    CheckboxIcon = <CheckboxDisabled />;
  } else {
    CheckboxIcon = <CheckboxDefault />;
  }

  return (
    <div
      ref={ref}
      aria-disabled={disabled || undefined}
      aria-selected={isSelected}
      role="option"
      id={id}
      className={cx(stylesheet.option, {
        [stylesheet.isHighlighted]: isHighlighted,
      })}
      onClick={() => !disabled && onClick && onClick()}
      onMouseMove={() => !disabled && onHighlight && onHighlight(value)}
    >
      {isRedesignFlagOn ? (
        multiple ? (
          !hide && CheckboxIcon
        ) : (
          <Checkmark
            className={stylesheet.selectedIcon}
            width={20}
            height={20}
          />
        )
      ) : null}
      {/* Hide label if its multi select since checkbox for multi select renders a label */}
      {children || value}
      {/* Always render the tick mark to include it in width calculations for
+      listboxes inside popups (in particular, SelectInline's). We adjust the
+      CSS `visibility`. Note that the tick mark is purely visual and not exposed
+      to assistive technology; the `aria-selected` state is. */}
      {isRedesignFlagOn ? null : (
        <TickIcon className={stylesheet.selectedIcon} />
      )}
    </div>
  );
}

export default Option;

import React from 'react';

import Select, { Option } from '../Select';

function pad(input: $TSFixMe) {
  return String(input).padStart(2, '0');
}

const formatTime = (
  twentyFourHour: $TSFixMe,
  hour: $TSFixMe,
  minute: $TSFixMe,
) => {
  // Adds consistency with time display as referenced in styleguide
  // See https://styleguide.mailchimp.com/grammar-and-mechanics/#header-4-time
  const min = minute > 0 ? ':' + pad(minute) : ' ';
  const ampm = hour < 12 || hour === 24 ? 'am' : 'pm';
  return twentyFourHour
    ? pad(hour) + ':' + pad(minute)
    : (hour % 12 || 12) + min + ampm;
};

function getTimes(twentyFourHour = false, min: $TSFixMe, max: $TSFixMe) {
  const [minHour, minMinute] = min.split(':').map(Number);
  const [maxHour, maxMinute] = max.split(':').map(Number);
  const times = [];

  const minTime = minHour * 60 + minMinute;
  const maxTime = maxHour * 60 + maxMinute;

  for (let time = minTime; time <= maxTime; time += 15) {
    const minute = time % 60;
    const hour = Math.floor(time / 60);
    const formatted = formatTime(twentyFourHour, hour, minute);

    times.push({
      formatted,
      value: `${hour}:${pad(minute)}`,
    });
  }

  return times;
}

export type InputTimeProps = {
  disabled?: boolean;
  error?: string;
  helpText?: React.ReactNode;
  hideLabel?: boolean;
  label?: React.ReactNode;
  max?: string;
  min?: string;
  onChange: $TSFixMeFunction;
  twentyFourHour?: boolean;
  value: string;
};

const InputTime = React.forwardRef<$TSFixMe, InputTimeProps>(function InputTime(
  {
    twentyFourHour = false,
    min = '0:00',
    max = '23:59',
    label,
    hideLabel,
    ...props
  },
  forwardedRef,
) {
  return (
    <Select
      searchable
      label={label}
      hideLabel={hideLabel}
      {...props}
      ref={forwardedRef}
    >
      {getTimes(twentyFourHour, min, max).map(({ formatted, value }) => (
        <Option key={value} label={formatted} value={value}>
          {formatted}
        </Option>
      ))}
    </Select>
  );
});

export default InputTime;

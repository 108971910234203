import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import stylesheet from './NumericBadge.css';

export type NumericBadgeProps = PropsWithChildren<{
  className?: string;
  label: string;
  type?: 'default' | 'error';
  icon?: React.ReactNode;
  iconLabel?: string;
}>;

const NumericBadge = React.forwardRef<HTMLSpanElement, NumericBadgeProps>(
  function NumericBadge(
    { label, type = 'default', icon, iconLabel, className },
    ref,
  ) {
    return (
      <span ref={ref} className={stylesheet.root}>
        <span
          className={cx(className, stylesheet.label, stylesheet[type], {
            [stylesheet.pinned]: icon,
          })}
        >
          {label}
        </span>
        {icon && (
          <span className={stylesheet.icon}>
            {icon} <span className="wink-visually-hidden">{iconLabel}</span>
          </span>
        )}
      </span>
    );
  },
);

export default NumericBadge;

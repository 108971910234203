import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

export const TranslateWizardBreadcrumbs = (
  currentStepNum: number,
  stepNamesLength: number,
) => {
  return {
    wizardBreadcrumbsLabelText: useDsTranslateMessage({
      id: 'mcds.wizard_breadcrumbs.label_text',
      defaultMessage: 'Breadcrumbs',
    }),
    wizardBreadcrumbsStepText: useDsTranslateMessage({
      id: 'mcds.wizard_breadcrumbs.step_text',
      defaultMessage: 'Step:',
    }),
    wizardBreadcrumbsFutureStepText: useDsTranslateMessage({
      id: 'mcds.wizard_breadcrumbs.future_step_text',
      defaultMessage: `Step ${currentStepNum} of ${stepNamesLength}:`,
      values: {
        currentStepNum: currentStepNum,
        stepNamesLength: stepNamesLength,
      },
    }),
  };
};

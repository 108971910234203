import React, { useContext, createContext, AllHTMLAttributes } from 'react';
import cx from 'classnames';
import ClassicPortal from '@mc/components/Portal';
import useId from '@mc/hooks/useId';
import { useInteropContext } from '../WinkStyles';
import Theme, { useThemeContext, useSubbrandContext } from '../Theme';

export const PortalStackContext = createContext('');

export type PortalProps = {
  children?: React.ReactNode;
  mountNode?: () => HTMLElement | null;
} & AllHTMLAttributes<HTMLElement>;

/**
 * Wraps the Portal component's children with an interop wrapper, if needed.
 */
const Portal = ({ children, ...props }: PortalProps) => {
  const portalId = useId();
  const oldStack = useContext(PortalStackContext);
  const newStack = oldStack ? `${portalId}:${oldStack}` : portalId;

  const interopContext = useInteropContext();
  const theme = useThemeContext() as 'light' | 'dark' | undefined;
  const subbrand = useSubbrandContext();

  return (
    <PortalStackContext.Provider value={newStack}>
      <ClassicPortal portalStack={newStack} {...props}>
        <Theme theme={theme} subbrand={cx(interopContext, subbrand)}>
          {children}
        </Theme>
      </ClassicPortal>
    </PortalStackContext.Provider>
  );
};

export default Portal;
import React from 'react';
import cx from 'classnames';
import ContentArea from '../ContentArea';
import stylesheet from './PageContent.css';

export type PageContentProps = {
  children?: React.ReactNode;
  /** Set this to false to remove the bottom padding applied to the container. */
  gutterBottom?: boolean;
  /** Set this to false to remove the top padding applied to the container. */
  gutterTop?: boolean;
  /** Most pages should use standard (which is the default). Use wide for full width sizes, use narrow for tightly constrained content.  */
  size?: 'standard' | 'narrow' | 'wide';
};

/**
 * Wrap this around the outermost content of a page. It will provide fluid padding and width defaults around your content.
 *
 * Note that when used with `PageHeader`, `PageContent` should be placed immediately below and not wrapping `PageHeader`, and both `PageHeader` and `PageContent`
 * should be wrapped by `Page`.
 */
const PageContent = React.forwardRef<HTMLDivElement, PageContentProps>(
  function PageContent(
    { size = 'standard', children, gutterBottom = true, gutterTop = true },
    forwardedRef,
  ) {
    return (
      <ContentArea
        size={size}
        className={cx({
          [stylesheet.withBottomGutter]: gutterBottom,
          [stylesheet.withTopGutter]: gutterTop,
        })}
        ref={forwardedRef}
      >
        {children}
      </ContentArea>
    );
  },
);

export default PageContent;

import React, {
  MouseEventHandler,
  ReactNode,
  HTMLAttributes,
  Ref,
} from 'react';
import cx from 'classnames';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import ButtonOrLink from '../ButtonOrLink';
import stylesheet from './IconButton.css';

type ButtonProps = {
  htmlType?: 'submit' | 'reset' | 'button';
  href?: '' | undefined | null;
  target?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

type AnchorProps = {
  href?: string | undefined;
  target?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  rel?: string;
};

export type IconButtonProps = {
  /** A custom class name that is appended to the button classes. */
  className?: string;
  /** Makes the button unclickable */
  disabled?: boolean;
  /**
   * Icon button will only take the icon component to render,
   * the component's name should match the one in wink-icon.
   * The whole syntax for rendering component needs to be included.
   * i.e. `<MenuRightIcon />`
   * For a list of possible icons, see
   * `web/js/src/node_modules/@mc/wink-icons/dist/react/index.js`
   */
  icon: ReactNode;
  /** The purpose of this button. This is relayed to assistive technology. */
  label: React.ReactNode;
  /**
   * These are the allowable behaviors of native buttons:
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#type
   */
  htmlType?: 'submit' | 'reset' | 'button';
  'data-testid'?: string;
  /** The different variants of IconButton */
  type?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'destructive';
  /** Size of the button */
  size?: 'small' | 'medium' | 'large';
} & (ButtonProps | AnchorProps) &
  HTMLAttributes<HTMLElement>;

/**
 * A button that displays only an icon from `@mc/wink-icons`. Requires a
 * visually hidden label for assistive technology.
 */
const IconButton = React.forwardRef<HTMLElement, IconButtonProps>(
  function IconButton(
    {
      className,
      disabled = false,
      icon,
      label,
      onClick,
      href,
      type = 'quaternary',
      size = 'medium',
      ...rest
    },
    forwardedRef,
  ) {
    if (mcdsFlagCheck('xp_mcds_redesign_components_molecules')) {
      const redesignButtonProps = {
        className: cx(
          stylesheet.root,
          { [stylesheet[type]]: type && type !== 'quaternary' },
          { [stylesheet[size]]: size && size !== 'medium' },
          className,
        ),
        htmlType: ('htmlType' in rest && rest.htmlType) || 'button',
        href: href || undefined,
        disabled: disabled,
        onClick: onClick,
        ref: forwardedRef as Ref<HTMLButtonElement> | undefined,
        ...rest,
      };

      return (
        <ButtonOrLink
          type={redesignButtonProps.htmlType}
          {...redesignButtonProps}
        >
          <span className="wink-visually-hidden">{label}</span>
          {icon}
        </ButtonOrLink>
      );
    }

    const element = href ? 'a' : 'button';

    if (element === 'button') {
      if ('type' in rest && rest.type) {
        rest.type = ('htmlType' in rest && rest.htmlType) || 'button'; //To prevent buttonProps.type override
      }

      const buttonProps = {
        className: cx(stylesheet.root, className),
        disabled: disabled,
        onClick: onClick,
        ref: forwardedRef,
        type: ('htmlType' in rest && rest.htmlType) || 'button',
        ...rest,
      };

      return React.createElement(
        element,
        buttonProps,
        <React.Fragment>
          <span className="wink-visually-hidden">{label}</span>
          {icon}
        </React.Fragment>,
      );
    }
    const anchorProps = {
      className: cx(stylesheet.root, className),
      disabled: disabled,
      ref: forwardedRef,
      href: href,
      onClick: onClick,
      ...rest,
    };

    if (rest.target === '_blank') {
      anchorProps.rel = 'noreferrer noopener';
    }

    if (anchorProps.disabled) {
      anchorProps.href = undefined;
      anchorProps.onClick = (event: { preventDefault: () => void }) => {
        event.preventDefault();
      };
    }
    return React.createElement(
      element,
      anchorProps,
      <React.Fragment>
        <span className="wink-visually-hidden">{label}</span>
        {icon}
      </React.Fragment>,
    );
  },
);

export default IconButton;

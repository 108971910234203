import React from 'react';

import { TickIcon } from '@mc/wink-icons';
import ActionList, { ActionListItem } from '../ActionList';
import stylesheet from './PageSwitcher.css';

export type PageSwitcherProps = {
  children: React.ReactNode;
  label: string;
};

function PageSwitcher({ children, label, ...props }: PageSwitcherProps) {
  let currentPageLabel;

  const filteredChildren = React.Children.toArray(children).filter(Boolean);
  filteredChildren.forEach((child) => {
    if (
      (child as $TSFixMe).props['aria-current'] ||
      (child as $TSFixMe).props.isCurrent
    ) {
      currentPageLabel = (child as $TSFixMe).props.label;
    }
  });

  return (
    <nav aria-label={label} className={stylesheet.nav}>
      {/* TODO: Should this be a <select>? */}
      <ActionList
        label={currentPageLabel}
        type="inline"
        placement="bottom-start"
        {...props}
      >
        {filteredChildren}
      </ActionList>
    </nav>
  );
}

export type PageSwitcherItemProps = {
  'aria-current'?: 'page' | 'step';
  children?: string;
  href?: string;
  isCurrent?: boolean;
  label: string;
};

export function PageSwitcherItem({
  children,
  label,
  ...props
}: PageSwitcherItemProps) {
  return (
    <ActionListItem
      {...props}
      label={label || children}
      iconEnd={props['aria-current'] ? <TickIcon /> : undefined}
    />
  );
}

export default PageSwitcher;

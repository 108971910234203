import React, { useContext } from 'react';
import chainHandlers from '@mc/fn/chainHandlers';
import Button, { ButtonProps } from '../Button';
import FormContext from './FormContext';
import stylesheet from './ResetButton.css';
import { FormValuesType } from './Form';

export type ResetButtonProps<T = FormValuesType> = {
  valuesOnReset?: T;
} & Omit<ButtonProps, 'htmlType' | 'className'>;

/**
 * Wrapper around the `Button` component. Clicking this button triggers the
 * `Form`'s `resetForm` hook.
 *
 * Props passed to `ResetButton` are applied to the inner `Button` component.
 */
function ResetButton({
  onClick = () => {},
  valuesOnReset,
  ...props
}: ResetButtonProps) {
  const { resetForm } = useContext(FormContext);
  const handleClick = chainHandlers(onClick, () => resetForm(valuesOnReset));
  return (
    <Button
      {...props}
      className={stylesheet.button}
      onClick={handleClick}
      htmlType="reset"
    />
  );
}

export default ResetButton;

import React, { useEffect, HTMLAttributes } from 'react';
import cx from 'classnames';
import { Checkmark } from '@design-systems/icons';
import useId from '@mc/hooks/useId';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import {
  ariaDescribedByIds,
  ariaLabelledByIds,
  formatError,
  ERROR_MUST_PROVIDE_LABEL,
} from '../utils';
import TickIconSmall from './TickIconSmall';
import stylesheet from './ToggleButton.css';

export type ToggleButtonProps = {
  /** Pass an element's ID to include its text content as part of this component's accessible name. */
  'aria-labelledby'?: string;
  /** disabled */
  disabled?: boolean;
  /** Help text for toggle */
  helpText?: string;
  /** Visually hides the label provided by the label prop. Used for special cases only. Removes default alignment. */
  hideLabel?: boolean;
  /** The label of the toggle. Even if visually hidden, provide a label for assistive technology. */
  label?: React.ReactNode;
  /** Help text for toggle */
  labelDirection?: 'left' | 'right';
  /** Triggers callback function when toggle state changes*/
  onChange: (value: boolean) => void;
  /** Specify if toggle is on/off */
  value: boolean;
} & HTMLAttributes<HTMLButtonElement>;

/**
 * Unlike checkboxes, they only exist as single elements and don’t appear in
 * sequences. Toggles are independent of each other, and interacting with one
 * doesn’t affect others.
 */
const ToggleButton = React.forwardRef<HTMLButtonElement, ToggleButtonProps>(
  function ToggleButton(
    {
      'aria-labelledby': ariaLabelledBy,
      className,
      helpText,
      onChange,
      onClick,
      disabled = false,
      hideLabel = false,
      value = false,
      label,
      labelDirection = 'left',
      ...rest
    },
    forwardedRef,
  ) {
    useEffect(() => {
      if (!label && !ariaLabelledBy && __DEV__) {
        throw new Error(formatError(ERROR_MUST_PROVIDE_LABEL, 'ToggleButton'));
      }
    }, [label, ariaLabelledBy]);

    const labelId = useId();
    const helpTextId = useId();

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(e);
      }
      onChange(!value);
    };

    return (
      <div
        className={cx(
          stylesheet.root,
          { [stylesheet[labelDirection]]: labelDirection !== 'left' },
          className,
          {
            [stylesheet.hideLabel]: hideLabel,
          },
        )}
      >
        <>
          <div className={cx(stylesheet.content)}>
            {label && (
              <div
                id={labelId}
                className={cx(
                  stylesheet.label,
                  hideLabel && 'wink-visually-hidden',
                )}
              >
                {label}
              </div>
            )}
            {!hideLabel && helpText && (
              <div className={stylesheet.helpText} id={helpTextId}>
                {helpText}
              </div>
            )}
          </div>
          <div className={cx(stylesheet.toggleGroup)}>
            <button
              disabled={disabled}
              className={stylesheet.toggle}
              type="button"
              aria-pressed={value}
              aria-labelledby={ariaLabelledByIds(
                ariaLabelledBy ?? '',
                label ? labelId : '',
              )}
              aria-describedby={ariaDescribedByIds(helpText ? helpTextId : '')}
              onClick={handleClick}
              ref={forwardedRef}
              {...rest}
            >
              {value &&
              mcdsFlagCheck('xp_mcds_redesign_components_molecules') ? (
                <Checkmark size="xsmall" color="var(--color-icon-inverse)" />
              ) : (
                <TickIconSmall className={stylesheet.tickIcon} />
              )}
              <span className={stylesheet.knob}></span>
            </button>
          </div>
        </>
      </div>
    );
  },
);

export default ToggleButton;

import React, { useContext } from 'react';

import { CrossIcon } from '@mc/wink-icons';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';
import IconButton from '../IconButton';
import { ModalCloseContext } from './Modal';

import stylesheet from './Modal.css';

const ModalCloseButton = () => {
  const onRequestClose = useContext(ModalCloseContext);
  // Translate default text
  const closeModalText = useDsTranslateMessage({
    id: 'mcds.modal.close_modal_text',
    defaultMessage: 'Close Modal',
  });

  return (
    <IconButton
      className={stylesheet.closeButton}
      icon={<CrossIcon />}
      label={closeModalText}
      // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type '(MouseEv... Remove this comment to see the full error message
      onClick={onRequestClose}
    />
  );
};

export default ModalCloseButton;

import React, { Component, FormEventHandler, ReactNode } from 'react';
import { generateRandomString } from '@mc/fn/rando';
import { getNestedValue, setNestedValue } from '@mc/fn/nestedValue';
import {
  ValidationErrorMessage,
  ValidationInput,
  Validator,
} from '@mc/validation';
import StackLayout from '../StackLayout';
import FormContext from './FormContext';
export type ValidationFunction = (
  value: ValidationInput<unknown>,
  values?: unknown,
) => Record<string, ValidationErrorMessage>;

export type FormValuesType = Record<string, unknown>;

export type ValidationType =
  | Record<string, Validator<unknown>>
  | ValidationFunction;

export type FormOnChangeHandler = <TName extends string, TValue>(
  changedValue: { [k in TName]: TValue },
  formValues: { [k in TName]: TValue },
  fieldName: TName,
) => void;

export type FormSubmitResult = {
  errors: Record<string, ValidationErrorMessage>;
  values: FormValuesType;
};

export type FormOnSubmitHandler = <TFormValue>(
  data: TFormValue,
) => FormSubmitResult;

export type FormProps = {
  /**
   * Pass autoComplete="off" as a prop to disable browser autocompletion for
   * the form
   */
  autoComplete?: string;
  /**
   * Children can either be a normal set of nodes, ideally comprising of
   * `<FormField>`s, `<SubmitButton>`, `<ResetButton>`, etc. OR a function
   * that will give you direct access to the current form state
   */
  children?: Validator<unknown> | ReactNode;
  /**
   * `Form` uses `StackLayout` to provide a default spacing between items.
   * This property adjusts that spacing.
   */
  gap?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  /**
   * This will toggle which type of field indicator will show for the entire form: required or
   * optional.
   */
  hasRequiredLabel?: boolean;
  /**
   * If `id` is not supplied, an id of `form-[a random number]` will be
   * generated automatically. All FormFields will inherit the form's id to
   * create their own ids of `formID-fieldName`.
   */
  id?: string;
  /**
   * If you need to initialize the form in a non-pristine state you can pass
   * initial state values here. **This mostly exists for rare circumstances.
   * Avoid using this if at all possible.**
   */
  initialState?: {
    changed?: string[];
    hasSubmitted?: boolean;
    isSubmitting?: boolean;
  };
  /**
   * The values to set as the forms "initial state". These are used when the
   * form is initially created to populate field values and then if the form
   * is reset these values are restored.
   */
  initialValues?: FormValuesType; //Maybe we need to parameterize this
  /**
   * Any time a value is changed, onChange is triggered. This callback has two
   * arguments: the first is an object containing only the changed value(s),
   * the second is the entire changed form data set.
   */
  onChange?: FormOnChangeHandler;
  /**
   * When a form submit event is fired, this callback is triggered. If a
   * promise is returned in this callback, the promise is honored before
   * completing. It has one argument which is the form values in the current
   * form state. This will only trigger if the form is considered "valid", see
   * the "validation" prop for more. If an object is returned here containing
   * keys of `values` and/or `errors` they will be merged in with the existing
   * values and errors form state. Be careful to not accidentally return those
   * values as it could result in accidentally erasing your form state.
   */
  onSubmit?: FormOnSubmitHandler;
  /**
   * This will toggle whether or not we reset the form on a successful
   * submit
   */
  resetOnSubmit?: boolean;
  /**
   * Validation can come in two syntaxes:
   *
   *  1. An object whose values are functions. Each function is run
   *     independently and if it returns null, is removed from the resulting
   *     errors object, otherwise it expects to be a string. This should
   *     usually map directly to the fields defined for the form.
   *
   *     For most common validation patterns, use `@mc/validation` which
   *     provides standard functions that can be mapped directly to the field.
   *
   *  2. A function that receives all the values of the given form state and
   *     should return either null if there are no errors or an object of keys
   *     and error messages as their values for any errors received. Use this
   *     method only if the validation pattern is complex and syntax 1 can't
   *     handle it easily. `<FormField>` will forward any errors that match
   *     its name to the consuming component. For example if the result errors
   *     object looks like: `{ foo: 'There was an error' }`, then `<FormField
   *     name="foo" component={Bar} />` would automatically show the error
   *     message and set a prop of `isInvalid` to `true` to the `Bar`
   *     component.
   */
  validation?: ValidationType;
};

export type FormState = {
  isSubmitting: boolean;
  hasSubmitted: boolean;
  changed: string[];
  formId: string;
  resetKey: string;
  values: FormValuesType;
  requiredFieldIndicator: boolean;
  optionalFieldIndicator: boolean;
  errors: Record<string, ValidationErrorMessage>;
  isValid: boolean;
  setValue: (name: string) => (value: FormValuesType) => void;
  submitForm: $TSFixMe;
  resetForm: (initialValues?: FormValuesType) => void;
};

export type FormPropsWithDefaultProps = FormProps & typeof Form.defaultProps;

/**
 * A wrapper around `<form>` that also manages data and UI state for our most
 * common form patterns.
 *
 * Under the hood, it manages all the values, errors, and state of a form and
 * provides an `onSubmit` callback for submitting those values to an API, local
 * storage, etc.
 */
class Form extends Component<FormPropsWithDefaultProps, FormState> {
  static defaultProps = {
    gap: 6,
    hasRequiredLabel: true,
    initialState: {
      hasSubmitted: false,
      isSubmitting: false,
      changed: [],
    },
    initialValues: {},
    onChange: () => {},
    onSubmit: () => {},
    resetOnSubmit: false,
    validation: {},
  };

  constructor(props: FormPropsWithDefaultProps) {
    super(props);
    const { errors, isValid } = this.validate(
      props.validation,
      props.initialValues,
    );
    this.state = {
      isSubmitting: this.props.initialState.isSubmitting,
      hasSubmitted: this.props.initialState.hasSubmitted,
      changed: this.props.initialState.changed,
      formId: props.id || `form-${generateRandomString()}`,
      resetKey: generateRandomString(),
      values: props.initialValues,
      requiredFieldIndicator: props.hasRequiredLabel,
      optionalFieldIndicator: !props.hasRequiredLabel,
      errors,
      isValid,
      setValue: this.setValue,
      submitForm: this.submitForm.bind(this),
      resetForm: this.resetForm,
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validate(rules: ValidationType, values: unknown) {
    let errors: Record<string, ValidationErrorMessage> = {};
    if (typeof rules === 'function') {
      errors = rules(values);
    } else {
      errors = Object.keys(rules).reduce((memo, key) => {
        const value = getNestedValue(values, key);
        const error = rules[key](value, values);
        if (error) {
          memo[key] = error;
        }

        return memo;
      }, {} as Record<string, ValidationErrorMessage>);
    }

    return {
      isValid: Object.keys(errors).length === 0,
      errors,
    };
  }

  setValue = (name: string) => {
    return (value: unknown) => {
      this.setState(
        (prevState: FormState) => {
          const values = setNestedValue(prevState.values, name, value);
          const { errors, isValid } = this.validate(
            this.props.validation,
            values,
          );

          const previousSet = new Set([...prevState.changed]);

          /**
           * Ensures that a manual update to the form, bringing it back to its initial state,
           * doesn't get stored inside of the "changed" array.
           */
          if (previousSet.has(name)) {
            if (
              JSON.stringify(this.props.initialValues[name]) ===
              JSON.stringify(value)
            ) {
              previousSet.delete(name);
            }
          } else {
            previousSet.add(name);
          }

          const changed = Array.from(previousSet);
          return {
            errors,
            isValid,
            values,
            changed,
          };
        },
        () =>
          // return only the nested changed value
          this.props.onChange(
            setNestedValue({}, name, value),
            this.state.values,
            name,
          ),
      );
    };
  };

  resetForm = (initialValues?: FormValuesType) => {
    const { validation } = this.props;
    const resetValues = initialValues || this.props.initialValues;
    const { errors, isValid } = this.validate(validation, resetValues);
    this.setState({
      resetKey: generateRandomString(),
      values: resetValues,
      isSubmitting: false,
      hasSubmitted: false,
      changed: [],
      isValid,
      errors,
    });
  };

  submitForm = (additionalValues = {}) => {
    const { values } = this.state;
    const { validation, onSubmit, resetOnSubmit } = this.props;
    const data = { ...values, ...additionalValues };
    const { isValid, errors } = this.validate(validation, data);
    this.setState({
      isSubmitting: true,
      hasSubmitted: true,
      isValid,
      errors,
    });

    if (isValid) {
      return Promise.resolve(onSubmit(data)).then((result) => {
        if (this._isMounted) {
          const resultHasErrors = result && result.errors;
          const resultHasValues = result && result.values;

          const successfullyResolved =
            !resultHasErrors || Object.keys(result.errors).length === 0;

          if (successfullyResolved && resetOnSubmit) {
            this.resetForm();
            return;
          }

          this.setState((prevState: FormState) => {
            // If we detect values or errors returned from the onSubmit handler,
            // merge them into the form state
            const valuesAfterSubmit = resultHasValues
              ? { ...prevState.values, ...result.values }
              : prevState.values;
            const errorsAfterSubmit = resultHasErrors
              ? { ...prevState.errors, ...result.errors }
              : prevState.errors;

            return {
              values: valuesAfterSubmit,
              errors: errorsAfterSubmit,
              isValid: Object.keys(errorsAfterSubmit).length === 0,
              isSubmitting: false,
            };
          });
        }
      });
    }

    this.setState({
      errors,
      isValid,
      isSubmitting: false,
    });
  };

  handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    this.submitForm();
  };

  render() {
    const { children, gap } = this.props;
    return (
      // This is the bread and butter of Form. What we do here is centralize all
      // the form state into <Form> but using context we provide each field with
      // a consumer of its value and change hooks to update it.
      <FormContext.Provider value={this.state}>
        <StackLayout
          as="form"
          gap={gap}
          noValidate
          key={this.state.resetKey}
          onSubmit={this.handleSubmit}
          autoComplete={this.props.autoComplete}
          id={this.state.formId}
        >
          {typeof children === 'function'
            ? children({ ...this.state })
            : children}
        </StackLayout>
      </FormContext.Provider>
    );
  }
}

export default Form;

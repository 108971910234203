import React, { useState } from 'react';
import cx from 'classnames';

import { truncate } from '@mc/fn/ellipsis';
import useBreakpoints from '@mc/hooks/useBreakpoints';

import Text from '../Text';
import ClusterLayout from '../ClusterLayout';
import StackLayout from '../StackLayout';
import Portal from '../Portal';
import TextButton from '../TextButton';
import Modal from '../Modal';
import ModalCloseButton from '../Modal/ModalCloseButton';
import ModalBody from '../Modal/ModalBody';
import ModalContent from '../Modal/ModalContent';
import ModalHeader from '../Modal/ModalHeader';

import stylesheet from './SiteAlert.css';

export type SiteAlertProps = {
  children: React.ReactNode;
  inline?: boolean;
};

/**
 * The SiteAlert component should only be called once in the Shell.js and not
 * be reused. Site Messages can be passed inside. SiteMessages should
 * also be contained in one file for searchability. Another option is creating
 * a "tunnel" for messages to append to the SiteAlert and be called from any
 * file (similar to Toast). Leaning towards the former method (similar set up in
 * current code).
 *
 * Proposed location: https://git.rsglab.com/product/mailchimp/blob/main/web/js/src/Main/core/Shell.js
 *
 * Docs should also be created to address how this is used.
 */

const SiteAlert = React.forwardRef<$TSFixMe, SiteAlertProps>(function SiteAlert(
  { children, inline, ...rest },
  forwardedRef,
) {
  /** Work around for peppercorn background. To refactor later once we address how 
  peppercorn components render in dark mode. */
  const classList = cx(stylesheet.root, 'dark-mode');

  const [isOpen, setIsOpen] = useState(false);
  const messageNumber = React.Children.count(children);
  const { isDesktop } = useBreakpoints();

  const alert =
    messageNumber > 0 ? (
      <ClusterLayout
        justifyContent="center"
        ref={forwardedRef}
        className={classList}
        // For story demo only. Needs to be refactored once implemented with the shell.
        // (ideally not have this as a prop if we can pass a story book control somehow instead for demo purposes)
        // @ts-expect-error TS(2322) FIXME: Type '{ children: (false | Element)[]; justifyCont... Remove this comment to see the full error message
        styles={!inline ? { position: 'fixed' } : null}
        {...rest}
      >
        {/* Preview summary of messages in SiteAlert if more than one message.*/}
        {/* To-do: Design wanted to be able to rank messages by importance. Probably pass a prop
        to indicate status level in order to sort them. */}
        {messageNumber > 1 && (
          <ClusterLayout nowrap>
            <div>
              <div className={stylesheet.number}>
                <p className="wink-visually-hidden">Number of notifications:</p>
                {messageNumber}
              </div>
            </div>

            <ClusterLayout justifyContent="flex-start">
              <Text appearance="small-bold">
                There are {messageNumber} important messages related to your
                account.
              </Text>
              <TextButton onClick={() => setIsOpen(true)}>
                View all alerts
              </TextButton>
            </ClusterLayout>
          </ClusterLayout>
        )}

        {/* Preview message in SiteAlert when there is only one message. */}
        {messageNumber === 1 && (
          <React.Fragment>
            {React.Children.map(children, (child) => {
              return (
                <ClusterLayout justifyContent="flex-start" nowrap>
                  {(child as $TSFixMe).props.icon}
                  <ClusterLayout>
                    <Text appearance="small">
                      <strong>{(child as $TSFixMe).props.title}</strong>{' '}
                      {/* Display ellipsis if character exceeds more than 100 characters on desktop.
    Also removes preview of description on mobile to save screen space. */}
                      {isDesktop &&
                        truncate((child as $TSFixMe).props.children, 100)}
                    </Text>

                    {/* Wrapper for actions */}
                    <ClusterLayout>
                      {/* Description is visible in a modal if not in desktop mode. */}
                      {!isDesktop ||
                      (child as $TSFixMe).props.children.length > 100 ? (
                        <TextButton onClick={() => setIsOpen(true)}>
                          View More Information
                        </TextButton>
                      ) : (
                        <React.Fragment>
                          {(child as $TSFixMe).props.action}
                          {(child as $TSFixMe).props.onClose && (
                            <TextButton
                              onClick={() => (child as $TSFixMe).props.onClose}
                            >
                              Dismiss
                            </TextButton>
                          )}
                        </React.Fragment>
                      )}
                    </ClusterLayout>
                  </ClusterLayout>
                </ClusterLayout>
              );
            })}
          </React.Fragment>
        )}

        {/* SiteAlert Modal */}
        <Modal
          isOpen={isOpen}
          onRequestClose={() => {
            setIsOpen(false);
          }}
        >
          <ModalCloseButton />
          <ModalHeader>Site Messages</ModalHeader>
          <ModalBody>
            <ModalContent>
              <StackLayout>{children}</StackLayout>
            </ModalContent>
          </ModalBody>
        </Modal>
      </ClusterLayout>
    ) : null;

  // @ts-expect-error TS(2322) FIXME: Type '{ children: Element | null; prependNode: tru... Remove this comment to see the full error message
  return !inline ? <Portal prependNode>{alert}</Portal> : alert;
});

export default SiteAlert;

import React, { useContext } from 'react';
import cx from 'classnames';
import Button, { ButtonProps } from '../Button';
import FormContext from './FormContext';
import stylesheet from './SubmitButton.css';

export type SubmitButtonProps = Omit<ButtonProps, 'htmlType'>;

/**
 * A wrapper around `@mc/wink/components/Button` that is aware of the form's
 * current validity and will trigger the `onSubmit` callback of `Form`.
 *
 * Props passed to `SubmitButton` are applied to the inner `Button` component.
 */
function SubmitButton(props: SubmitButtonProps) {
  const { isSubmitting } = useContext(FormContext);
  return (
    <Button
      {...props}
      className={cx(stylesheet.button, props.className)}
      isLoading={isSubmitting || props.isLoading}
      disabled={isSubmitting || props.disabled}
      htmlType="submit"
    />
  );
}

export default SubmitButton;

import React from 'react';
import { ValidationErrorMessage } from '@mc/validation';
import { FormValuesType } from './Form';

export type FormContextType<T = FormValuesType> = {
  values: T;
  errors: Record<keyof T, ValidationErrorMessage>;
  resetForm: (valuesOnReset?: T) => void;
  isSubmitting: boolean;
  hasSubmitted?: boolean;
  isValid?: boolean;
  requiredFieldIndicator?: boolean;
  optionalFieldIndicator?: boolean;
  formId?: string;
  setValue: (name: string) => $TSFixMe;
};

const FormContext = React.createContext<FormContextType>({
  values: {},
  errors: {},
} as FormContextType);

export default FormContext;

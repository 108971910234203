import React, { useEffect, useState } from 'react';
import cx from 'classnames';
// eslint-disable-next-line no-restricted-imports
import { useTransition, animated } from 'react-spring';
import usePrefersReducedMotion from '@mc/hooks/usePrefersReducedMotion';
import useId from '@mc/hooks/useId';
import useBodyClass from '@mc/hooks/useBodyClass';
import Dialog from '../Dialog';

import stylesheet from './Modal.css';

const AnimatedDialog = animated(Dialog);

// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
const ModalCloseContext = React.createContext();
// @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
const ModalIdContext = React.createContext();

export type ModalProps = {
  'aria-labelledby'?: string;
  children?: React.ReactNode;
  className?: string;
  containerClassName?: string;
  isCloseable?: boolean;
  isOpen: boolean;
  onRequestClose: $TSFixMeFunction;
  width?: 'xs' | 'sm' | 'md' | 'lg';
};

/**
 * Modals act as a focused dialog with our users.
 * Typically, they should only be used to present inconsequential information,
 * act as a confirmation, or perform a singular task or action (such as fill out a form).
 */

const Modal = React.forwardRef<$TSFixMe, ModalProps>(function Modal(
  {
    children,
    className,
    containerClassName,
    isCloseable = true,
    isOpen,
    width = 'md',
    onRequestClose,
    'aria-labelledby': ariaLabelledby,
    ...rest
  },
  forwardedRef,
) {
  const id = useId();
  const prefersReducedMotion = usePrefersReducedMotion();

  useBodyClass(isOpen, stylesheet.lockScroll);

  const animationConfig = {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  };

  const transitions = useTransition(
    isOpen,
    prefersReducedMotion ? {} : animationConfig,
  );

  return transitions((style, item) => {
    return (
      item && (
        <AnimatedDialog
          className={cx(stylesheet.container, containerClassName)}
          onRequestClose={onRequestClose}
          aria-labelledby={ariaLabelledby || id}
        >
          <ModalCloseContext.Provider value={onRequestClose}>
            <ModalIdContext.Provider value={id}>
              <div
                className={stylesheet.overlay}
                onClick={isCloseable ? onRequestClose : undefined}
              />
              <animated.div
                className={cx(stylesheet.root, className, stylesheet[width])}
                ref={forwardedRef}
                style={style}
                {...rest}
              >
                {children}
              </animated.div>
            </ModalIdContext.Provider>
          </ModalCloseContext.Provider>
        </AnimatedDialog>
      )
    );
  });
});

export { ModalCloseContext, ModalIdContext, Modal as default };

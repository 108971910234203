import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useRef,
  MouseEventHandler,
  FocusEventHandler,
  KeyboardEvent,
} from 'react';
import cx from 'classnames';
import scrollIntoView from 'scroll-into-view-if-needed';
import useId from '@mc/hooks/useId';
import chainHandlers from '@mc/fn/chainHandlers';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import Popup from '../Popup';
import ButtonOrLink from '../ButtonOrLink';
import SearchInput from '../SearchInput';
import Option, { OptionProps } from './Option';
import stylesheet from './Listbox.css';
import { TranslateListbox } from './TranslateListbox';

const ListboxModeContext = createContext(false);

type ListboxOptgroupProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  label: string;
};
const ListboxOptgroup = ({
  children,
  disabled,
  label,
}: ListboxOptgroupProps) => {
  const id = useId();
  return (
    <div
      role="group"
      aria-labelledby={id}
      aria-disabled={disabled}
      className={stylesheet.optgroupWrapper}
    >
      <div id={id} className={stylesheet.optgroup}>
        {label}
      </div>
      {children}
    </div>
  );
};

const defaultRenderSelectedValue = (
  selected: { children?: React.ReactNode; value: string }[],
  placeholder: React.ReactNode,
) => {
  return selected.length > 1
    ? `${selected.length} selected`
    : selected.length === 1
    ? selected.map((v) => v.children || v.value)
    : placeholder;
};

const defaultOptionsFilter = (value: string | undefined, option: string) => {
  return !!value && !option.toLowerCase().includes(value.toLowerCase());
};

export type ListboxProps = {
  'aria-labelledby'?: string;
  'aria-describedby'?: string;
  callToActionHref?: string;
  callToActionLabel?: string;
  callToActionOnClick?: MouseEventHandler<
    HTMLButtonElement | HTMLAnchorElement
  >;
  callToActionPrefixIcon?: React.ReactNode;
  callToActionDisabled?: boolean;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  emptyOption?: string;
  isPopupFixed?: boolean;
  matchTargetWidth?: boolean;
  multiple?: boolean;
  onBlur?: FocusEventHandler;
  onChange: (
    updatedValue: string | number | (string | number | undefined)[],
  ) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  onOpen?: () => void;
  onSearch?: (filter?: string) => void;
  optionsFilter?: (val: string | undefined, options: string) => boolean;
  placeholder?: React.ReactNode;
  renderSelectedValue?: (
    selected:
      | { children?: React.ReactNode; value: string }
      | { children?: React.ReactNode; value: string }[],
    placeholder: React.ReactNode,
  ) => React.ReactNode;
  searchable?: boolean;
  trigger?: React.ReactElement;
  value?: string | string[] | number | number[];
  offset?: number;
  placement?:
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'left'
    | 'left-start'
    | 'left-end';
};

/**
 * Listbox is a primitive component used to power other design system components.
 * It should *NEVER* be used directly in app code.
 */
const Listbox = React.forwardRef<HTMLElement, ListboxProps>(function Listbox(
  {
    callToActionOnClick,
    callToActionHref,
    callToActionLabel,
    callToActionPrefixIcon,
    callToActionDisabled,
    value,
    onChange,
    trigger: Trigger,
    emptyOption,
    placeholder,
    multiple = false,
    matchTargetWidth = false,
    renderSelectedValue = defaultRenderSelectedValue,
    className,
    onSearch = () => {},
    onOpen = () => {},
    optionsFilter = defaultOptionsFilter,
    searchable = false,
    children,
    disabled = false,
    isPopupFixed,
    offset,
    placement,
    ...props
  },
  forwardedRef,
) {
  // Translate default values
  const { noOptionsText, placeholderText } = TranslateListbox();
  emptyOption = emptyOption || noOptionsText;
  placeholder = placeholder || placeholderText;

  const [isExpanded, setIsExpanded] = useState(false);
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const isRedesignFlagOn = mcdsFlagCheck(
    'xp_mcds_redesign_components_molecules',
  );
  const isRedesignOrganismFlagOn = mcdsFlagCheck(
    'xp_mcds_redesign_components_organisms',
  );
  const initialHighlightedValue = (
    multi: boolean,
    val?: string | string[] | number | number[],
  ) => {
    if (multi && Array.isArray(val) && val.length > 0) {
      return val[0];
    }
    return typeof val === 'string' ? val : '';
  };
  const [highlightedValue, setHighlightedValue] = useState<string | number>(
    initialHighlightedValue(multiple, value),
  );

  const id = useId();
  const listboxRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const ctaRef = useRef<HTMLButtonElement | HTMLAnchorElement>(null);
  const onOpenCalled = useRef(false);
  const allOptions: OptionProps[] = [];
  const enabledOptions: OptionProps[] = [];
  const cloned: React.ReactElement[] = [];

  const _onSearch = useCallback(onSearch, []);

  useEffect(() => {
    if (!onOpenCalled.current && isExpanded) {
      onOpen();
      onOpenCalled.current = true;
    }
    if (!isExpanded) {
      onOpenCalled.current = false;
    }
  }, [isExpanded, onOpen]);

  useEffect(() => {
    if (!disabled) {
      if (filter && filter.length > 0 && !isExpanded) {
        setIsExpanded(true);
      }
      if (_onSearch) {
        _onSearch(filter);
      }
    } else if (isExpanded) {
      setIsExpanded(multiple);
    }
  }, [filter, isExpanded, multiple, disabled, _onSearch]);
  const handleSelect = (selectedValue: string | number) => {
    if (multiple) {
      // Preserve the highlighted value after the selection
      setHighlightedValue(selectedValue);

      if (!value) {
        onChange([selectedValue]);
      } else {
        const newValues = allOptions
          .filter((option) => {
            return (
              // adds and removes the clicked on child
              (option.value === selectedValue &&
                Array.isArray(value) &&
                !(value as (string | number)[]).includes(selectedValue)) ||
              // keep elements that already been selected
              (option.value !== selectedValue && option.isSelected)
            );
          })
          .map((option) => option.value);
        onChange(newValues);
      }
    } else {
      onChange(selectedValue);
      setIsExpanded(false);
    }

    setFilter(undefined);
  };

  // This loop determines a few things in a single iteration of each option:
  //
  // 1. create array to manage traversing/navigating the options
  // 2. the selected item (if one is selected)
  // 3. the highlighted item (used when navigating the options with keyboard)
  // 4. enabled/disabled logic
  // 5. clone children for display
  let index = 0;
  React.Children.forEach(children, (child) => {
    if (!child) {
      return;
    }
    const options =
      (child as React.ReactElement).type === 'optgroup'
        ? React.Children.toArray((child as React.ReactElement).props.children)
        : [child];
    let hasUnfilteredOptions =
      (child as React.ReactElement).type !== 'optgroup' || !filter;
    const currentIndex = index;
    // 1. create array to manage traversing/navigating the options
    options.forEach((option) => {
      if (React.isValidElement(option)) {
        // 2. the selected item
        const isSelected = multiple
          ? !value
            ? false
            : Array.isArray(value) &&
              (value as (string | number)[]).includes(option.props.value)
          : option.props.value === value
          ? true
          : // Do not output aria-selected="false" for single-select comboboxes.
            undefined;
        let filterText;
        if (option.props.label) {
          filterText = option.props.label;
        } else if (typeof option.props.children === 'string') {
          filterText = option.props.children;
        } else if (option.type !== ListboxOptgroup) {
          throw new Error(
            'Options of a searchable listbox must have string children or a label prop',
          );
        }
        const isFiltered = optionsFilter(filter, filterText);
        // 3. the highlighted item
        const isHighlighted =
          !isFiltered && option.props.value === highlightedValue;
        // 4. enabled/disabled logic
        const isDisabled =
          ((child as React.ReactElement).type === 'optgroup' &&
            (child as React.ReactElement).props.disabled) ||
          option.props.disabled;
        const isEnabled =
          option.type !== ListboxOptgroup && !isDisabled && !isFiltered;
        if (!isFiltered && (child as React.ReactElement).type === 'optgroup') {
          hasUnfilteredOptions = true;
        }
        const optionProps = {
          ...option.props,
          key: option.props.value,
          id: id + '-' + option.props.value,
          disabled: isDisabled,
          isSelected,
          isHighlighted,
          isFiltered,
          onHighlight: setHighlightedValue,
          multiple,
          onClick: () => {
            if (isEnabled) {
              handleSelect(option.props.value);
              setIsExpanded(multiple);
            }
          },
        };
        allOptions.push(optionProps);
        if (isEnabled) {
          enabledOptions.push(optionProps);
        }
        index += 1;
      }
    });
    // 5. Clone children for display
    if ((child as React.ReactElement).type === 'optgroup') {
      if (hasUnfilteredOptions) {
        const { children: childChildren, ...childProps } = (
          child as React.ReactElement
        ).props;
        cloned.push(
          <ListboxOptgroup {...childProps} key={childProps.label}>
            {React.Children.map(childChildren, (childChild, childIndex) => {
              return React.cloneElement(
                childChild,
                allOptions[currentIndex + childIndex],
              );
            })}
          </ListboxOptgroup>,
        );
      }
    } else {
      cloned.push(
        React.cloneElement(
          child as React.ReactElement,
          allOptions[currentIndex],
        ),
      );
    }
  });

  const handleKeyDown = (event: KeyboardEvent) => {
    if (disabled) {
      return;
    }

    switch (event.key) {
      case 'Enter':
        event.preventDefault();
        if (isExpanded) {
          // If using filtered options, select either the highlighted value or the originally selected value
          if (filter) {
            const selectedByFilter =
              enabledOptions.find(
                (option) => option.value === highlightedValue,
              ) || enabledOptions[0];

            if (selectedByFilter) {
              handleSelect(selectedByFilter.value ?? '');
            }
          } else {
            handleSelect(highlightedValue);
          }
        } else {
          setIsExpanded(true);
        }
        break;

      case 'Escape':
        if (isExpanded) {
          event.preventDefault();
          setIsExpanded(false);
        }
        break;

      case 'ArrowUp':
        event.preventDefault();
        if (isExpanded) {
          const highlightedIndex = enabledOptions.findIndex(
            (option) => option.isHighlighted,
          );
          const prev =
            (highlightedIndex - 1 + enabledOptions.length) %
            enabledOptions.length;
          setHighlightedValue(enabledOptions[prev].value ?? '');
        } else {
          setIsExpanded(true);
        }
        break;

      case 'ArrowDown':
        event.preventDefault();
        if (isExpanded) {
          const highlightedIndex = enabledOptions.findIndex(
            (option) => option.isHighlighted,
          );
          const next = (highlightedIndex + 1) % enabledOptions.length;
          setHighlightedValue(enabledOptions[next].value ?? '');
        } else {
          setIsExpanded(true);
        }
        break;

      case 'Home':
        if (isExpanded) {
          event.preventDefault();
          setHighlightedValue(enabledOptions[0].value ?? '');
        }
        break;

      case 'End':
        if (isExpanded) {
          event.preventDefault();
          setHighlightedValue(
            enabledOptions[enabledOptions.length - 1].value ?? '',
          );
        }
        break;

      // Emulating native select behavior
      // Options being open prevent tabs from changing focus
      case 'Tab':
        if (isExpanded && filter === undefined) {
          if (callToActionLabel && !event.shiftKey) {
            event.preventDefault();
            // eslint-disable-next-line no-unused-expressions
            ctaRef.current?.focus();
            setIsExpanded(true);
          }
        }
        break;

      default:
        break;
    }
  };

  const handleCtaKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab' && !event.shiftKey) {
      setIsExpanded(false);
    }
  };

  const handleTriggerOnBlur = () => {
    const defaultOnBlur = () => {};
    const onBlurHandler = props.onBlur || defaultOnBlur;

    const listboxHandler = () => {
      // Close the listbox unless there is an inline listbox search
      if (!searchable) {
        setIsExpanded(false);
      }
    };
    chainHandlers(onBlurHandler, listboxHandler);
  };

  useEffect(() => {
    if (isExpanded && listboxRef.current) {
      const firstSelectedValue = listboxRef.current.querySelector(
        '[aria-selected=true]',
      );
      if (firstSelectedValue) {
        scrollIntoView(firstSelectedValue, {
          block: 'nearest',
          scrollMode: 'if-needed',
          boundary: listboxRef.current,
        });
      }
    }
  }, [isExpanded]);

  return (
    <div className={cx(stylesheet.container, className)} ref={containerRef}>
      {/* @ts-expect-error TS(2604) FIXME: JSX element type 'Trigger' does not have any const... Remove this comment to see the full error message */}
      <Trigger
        {...props}
        disabled={disabled}
        isExpanded={isExpanded}
        placeholder={placeholder}
        renderSelectedValue={renderSelectedValue}
        filter={filter}
        onFilterChange={setFilter}
        options={enabledOptions}
        selected={enabledOptions.filter((option) => option.isSelected)}
        id={id + '-trigger'}
        role="combobox"
        aria-autocomplete={filter ? 'list' : 'none'}
        aria-haspopup="listbox"
        aria-controls={isExpanded ? id : undefined}
        aria-expanded={isExpanded}
        aria-activedescendant={
          isExpanded ? `${id}-${highlightedValue}` : undefined
        }
        onHighlight={setHighlightedValue}
        onSelect={handleSelect}
        onToggle={() => setIsExpanded((prev) => (disabled ? false : !prev))}
        onKeyDown={chainHandlers(props.onKeyDown || (() => {}), handleKeyDown)}
        onBlur={
          isRedesignOrganismFlagOn
            ? handleTriggerOnBlur
            : chainHandlers(props.onBlur || (() => {}), () =>
                setIsExpanded(false),
              )
        }
        ref={forwardedRef}
      />
      {isExpanded && (
        <Popup
          matchTargetWidth={matchTargetWidth}
          targetRef={containerRef}
          className={cx(stylesheet.popup, {
            [stylesheet.listbox]: isRedesignOrganismFlagOn,
          })}
          fixed={isPopupFixed}
          offset={isRedesignFlagOn ? offset : undefined}
          placement={isRedesignFlagOn ? placement : 'bottom'}
        >
          {/* Search Input within Listbox */}
          {isRedesignOrganismFlagOn && searchable && (
            <SearchInput
              label="Search"
              {...props}
              id={id}
              className={cx(stylesheet.searchInput)}
              placeholder={placeholder as string}
              value={filter}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onChange={(event) => {
                event.stopPropagation();
                setFilter(event.target.value);
              }}
              onKeyDown={chainHandlers(
                props.onKeyDown || (() => {}),
                handleKeyDown,
              )}
              onBlur={() => {
                setFilter(undefined);
              }}
            />
          )}
          <div
            className={!isRedesignOrganismFlagOn && stylesheet.listbox}
            // preventDefault stops the trigger from losing focus. It also stops
            // an optgroup from collapsing the listbox.
            onMouseDown={(event) => {
              event.preventDefault();
            }}
          >
            <div
              className={stylesheet.options}
              ref={listboxRef}
              role="listbox"
              id={id}
            >
              <ListboxModeContext.Provider value={true}>
                {!!filter && enabledOptions.length === 0 ? (
                  <Option disabled>{emptyOption}</Option>
                ) : (
                  cloned
                )}
              </ListboxModeContext.Provider>
            </div>
            {callToActionLabel && (
              <div
                className={
                  isRedesignFlagOn
                    ? cx(
                        stylesheet.callToAction,
                        callToActionDisabled
                          ? stylesheet.callToActionDisabled
                          : '',
                      )
                    : stylesheet.callToAction
                }
              >
                <ButtonOrLink
                  ref={ctaRef}
                  href={callToActionHref}
                  onClick={callToActionOnClick}
                  onKeyDown={handleCtaKeyDown}
                  className={stylesheet.callToActionButton}
                  disabled={
                    isRedesignFlagOn && callToActionDisabled ? true : false
                  }
                >
                  <>
                    {isRedesignFlagOn && callToActionPrefixIcon}
                    {callToActionLabel}
                  </>
                </ButtonOrLink>
              </div>
            )}
          </div>
        </Popup>
      )}
    </div>
  );
});

export { ListboxModeContext };

export default Listbox;

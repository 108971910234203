import React, { AllHTMLAttributes, PropsWithChildren } from 'react';
import cx from 'classnames';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import stylesheet from './Text.css';

export type TextProps = PropsWithChildren<{
  /* DEPRECATING: Please use size prop instead for new Heading mapping */
  appearance?:
    | 'heading-2'
    | 'heading-3'
    | 'heading-4'
    | 'heading-5'
    | 'heading-6'
    | 'large'
    | 'large-secondary'
    | 'large-bold'
    | 'medium'
    | 'medium-secondary'
    | 'medium-bold'
    | 'small'
    | 'small-secondary'
    | 'small-bold'
    | 'tiny'
    | 'tiny-secondary'
    | 'tiny-tertiary'
    | 'tiny-bold';
  /* Change the visual appearance of the heading. */
  size?:
    | 'heading-2'
    | 'heading-3'
    | 'heading-4'
    | 'heading-5'
    | 'heading-6'
    | 'large'
    | 'large-secondary'
    | 'large-bold'
    | 'medium'
    | 'medium-secondary'
    | 'medium-bold'
    | 'small'
    | 'small-secondary'
    | 'small-bold'
    | 'tiny'
    | 'tiny-secondary'
    | 'tiny-tertiary'
    | 'tiny-bold';
  as?: 'p' | 'span';
  className?: string;
  textWrap?: 'wrap' | 'nowrap' | 'balance' | 'pretty';
}> &
  AllHTMLAttributes<HTMLElement>;

const Text = React.forwardRef<
  HTMLParagraphElement & HTMLSpanElement,
  TextProps
>(function Text(
  {
    appearance = 'medium',
    as: Component = 'p',
    className,
    children,
    textWrap,
    size = 'medium',
    ...props
  },
  forwardedRef,
) {
  const isHeadingMappingFlagOn = mcdsFlagCheck('xp_mcds_redesign_heading_size');

  // MCDS Redesign heading sizes are much larger than existing when looking at them 1:1.
  // This maps our old headings to the equivalent redesign sizes. For reference: https://docs.google.com/document/d/1DLawBFOPELZGFvpldF_SwUKAkdZgqhvgZjzz4ZroKrU/edit?tab=t.0#heading=h.n44o0uvj9ftx
  // If deprecated appearance prop is used, map it to a lower visual size using the size prop to avoid scaling discrepancies.
  // This can be removed once the appearance prop is fully deprecated.
  if (appearance === 'heading-2') {
    size = 'heading-3';
  } else if (appearance === 'heading-3') {
    size = 'heading-5';
  } else if (appearance === 'heading-4') {
    size = 'heading-6';
  }
  // Otherwise all other headings are the same size.
  else {
    size = appearance;
  }

  return (
    <Component
      className={cx(
        stylesheet.root,
        { [stylesheet[appearance]]: !isHeadingMappingFlagOn },
        { [stylesheet[size]]: isHeadingMappingFlagOn },
        className,
        textWrap && stylesheet[`text-${textWrap}`],
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </Component>
  );
});

export default Text;

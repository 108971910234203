import React, { MouseEventHandler } from 'react';
import cx from 'classnames';
import {
  CrossIcon,
  FeaturedIcon,
  InfoCircleIcon,
  NotAllowedIcon,
  TickCircleIcon,
  WarningCircleIcon,
} from '@mc/wink-icons';
import {
  CircleCheckFill,
  CircleExclamationFill,
  CircleInfoFill,
  Close,
  TriangleExclamationFill,
} from '@design-systems/icons';
import useBreakpoints from '@mc/hooks/useBreakpoints';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import ClusterLayout from '../ClusterLayout';
import StackLayout from '../StackLayout';
import IconButton from '../IconButton';
import Portal from '../Portal';
import Text from '../Text';
import stylesheet from './FeedbackBlock.css';

type PortalWrapperProps = {
  children?: React.ReactNode;
  condition?: boolean;
};

const PortalWrapper = ({ condition, children }: PortalWrapperProps) => {
  // @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; prependNode: true; }'... Remove this comment to see the full error message
  return condition ? children : <Portal prependNode>{children}</Portal>;
};

type FeedbackIconProps = {
  variant?: string;
};

const FeedbackIcon = ({ variant }: FeedbackIconProps) => {
  const icon =
    variant === 'success' ? (
      <TickCircleIcon />
    ) : variant === 'warning' ? (
      <WarningCircleIcon />
    ) : variant === 'error' ? (
      <NotAllowedIcon />
    ) : variant === 'info' ? (
      <FeaturedIcon />
    ) : (
      <InfoCircleIcon />
    );

  return icon;
};

const FeedbackIconRedesign = ({ variant }: FeedbackIconProps) => {
  const icon =
    variant === 'success' ? (
      <CircleCheckFill />
    ) : variant === 'warning' ? (
      <CircleExclamationFill />
    ) : variant === 'error' ? (
      <TriangleExclamationFill />
    ) : (
      <CircleInfoFill />
    );
  return icon;
};

type ContentLayoutProps = {
  children?: React.ReactNode;
};

const ContentLayout = ({ children }: ContentLayoutProps) => {
  const isRedesignFlagOn = mcdsFlagCheck(
    'xp_mcds_redesign_components_molecules',
  );
  const { isDesktop, isPhone } = useBreakpoints();

  // Stack actions and content for responsive view and break at the same time.

  return isRedesignFlagOn ? (
    isPhone ? (
      <StackLayout gap={2} className={stylesheet.content}>
        {children}
      </StackLayout>
    ) : (
      <ClusterLayout
        gap={2}
        justifyContent="space-between"
        className={stylesheet.content}
        nowrap
      >
        {children}
      </ClusterLayout>
    )
  ) : isDesktop ? (
    <ClusterLayout
      gap={2}
      justifyContent="space-between"
      className={stylesheet.content}
      nowrap
    >
      {children}
    </ClusterLayout>
  ) : (
    <StackLayout gap={2} className={stylesheet.content}>
      {children}
    </StackLayout>
  );
};

export type FeedbackBlockProps = {
  /** Actions passed through with the TextButton or Link component */
  actions?: React.ReactNode;
  /** Copy that follows the bolded title of the feedback */
  children?: React.ReactNode;
  className?: string;
  /** Display inline if true. By default, the Feedback Block will append to the top of the page. */
  inline?: boolean;
  /** Function to dismiss FeedbackBlock */
  onClose?: MouseEventHandler<HTMLElement>;
  /** Bolded title */
  title: React.ReactNode;
  /** The different variants of FeedbackBlock */
  variant?: 'warning' | 'default' | 'success' | 'error';
};

const FeedbackBlock = React.forwardRef<HTMLDivElement, FeedbackBlockProps>(
  function FeedbackBlock(
    {
      actions,
      className,
      children,
      onClose,
      inline = false,
      title,
      variant = 'default',
      ...rest
    },
    forwardedRef,
  ) {
    /* With the redesign, icons are pulled from IDS iconography. Existing application uses wink icons */
    const isRedesignFlagOn = mcdsFlagCheck(
      'xp_mcds_redesign_components_molecules',
    );
    const classList = cx(stylesheet.root, stylesheet[variant], className);
    const { isDesktop, isPhone } = useBreakpoints();

    // Translation for default icon button label text
    const iconButtonLabelText = useDsTranslateMessage({
      id: 'mcds.feedback_block.icon_button_label_text',
      defaultMessage: 'Dismiss alert',
    });

    return (
      <PortalWrapper condition={inline}>
        <ClusterLayout
          gap={isRedesignFlagOn ? 3 : 4}
          nowrap
          className={classList}
          ref={forwardedRef}
          {...rest}
        >
          <span className={stylesheet.icon}>
            {isRedesignFlagOn ? (
              <FeedbackIconRedesign variant={variant} />
            ) : (
              <FeedbackIcon variant={variant} />
            )}
          </span>
          <ContentLayout>
            <div className={stylesheet.copy}>
              <Text appearance="small-bold">{title}</Text>
              <Text as="span" appearance="small-secondary">
                {children}
              </Text>
            </div>
            {isRedesignFlagOn ? (
              actions && (
                <ClusterLayout
                  gap={2}
                  nowrap={!isPhone}
                  justifyContent={!isPhone ? 'flex-end' : 'flex-start'}
                  className={stylesheet.actions}
                >
                  {actions}
                </ClusterLayout>
              )
            ) : (
              <ClusterLayout
                nowrap={isDesktop}
                justifyContent={isDesktop ? 'flex-end' : 'flex-start'}
                className={stylesheet.actions}
              >
                {actions}
              </ClusterLayout>
            )}
          </ContentLayout>
          {onClose && (
            <div>
              <IconButton
                label={iconButtonLabelText}
                icon={
                  isRedesignFlagOn ? (
                    <Close size={isPhone ? 'small' : 'xsmall'} />
                  ) : (
                    <CrossIcon />
                  )
                }
                onClick={onClose}
              />
            </div>
          )}
        </ClusterLayout>
      </PortalWrapper>
    );
  },
);

export default FeedbackBlock;

import { useContext } from 'react';
import { getNestedValue } from '@mc/fn/nestedValue';
import FormContext, { FormContextType } from './FormContext';

export type UseFormField = {
  id: string;
  value: $TSFixMe;
  error: string | null;
  isValid: boolean;
  onChange: (name: string) => $TSFixMe;
  formContext: FormContextType;
};

export default function useFormField(
  name: string,
): UseFormField | Record<string, never> {
  const formContext = useContext(FormContext);
  const { formId, values, errors, setValue } = formContext;

  // Bail on this if no name is defined because propTypes will handle surfacing this error
  if (!name) {
    return {};
  }

  return {
    id: `${formId}-${name}`,
    value: getNestedValue(values, name),
    error: errors[name] || null,
    isValid: !errors[name],
    onChange: setValue(name),
    formContext,
  };
}

import React, { useState, PropsWithChildren } from 'react';
import { ChevronDown } from '@design-systems/icons';
import cx from 'classnames';
import { MenuDownIcon } from '@mc/wink-icons';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import { Button } from '@mc/wink';
import Animate from '../Animate';
import stylesheet from './Disclosure.css';

export type DisclosureProps = PropsWithChildren<{
  children: React.ReactNode;
  heading: string;
  size?: 'large' | 'medium';
  expanded?: boolean;
}>;

const Disclosure = React.forwardRef<HTMLDivElement, DisclosureProps>(
  function Disclosure(
    { children, heading, size = 'medium', expanded = false },
    forwardedRef,
  ) {
    const [isExpanded, setIsExpanded] = useState(expanded);
    return (
      <div className={stylesheet.root} ref={forwardedRef}>
        {mcdsFlagCheck('xp_mcds_redesign_components_molecules') ? (
          <Button
            aria-expanded={isExpanded}
            type="plain"
            className={cx(stylesheet.heading, stylesheet[size])}
            onClick={() => setIsExpanded((value) => !value)}
          >
            <ChevronDown size="xsmall" />
            {heading}
          </Button>
        ) : (
          <button
            aria-expanded={isExpanded}
            type="button"
            className={cx(stylesheet.heading, stylesheet[size])}
            onClick={() => setIsExpanded((value) => !value)}
          >
            <MenuDownIcon />
            {heading}
          </button>
        )}
        <Animate
          animation="slide"
          easing="expressive"
          toggle={isExpanded}
          className={stylesheet.body}
        >
          {children}
        </Animate>
      </div>
    );
  },
);

export default Disclosure;

import React, { useRef, HTMLAttributes } from 'react';
import cx from 'classnames';
import Card, { CardImage } from '../Card';
import StackLayout from '../StackLayout';
import Text from '../Text';
import stylesheet from './ActionCard.css';

export type ActionCardProps = {
  /** Card image alt text. */
  alt?: string;
  /** Call-to-action text for Card. */
  cta?: React.ReactElement;
  /** Description for Card. */
  description?: string;
  /** Vertical orientation for action card. */
  isVertical?: boolean;
  /** Position placement for image. */
  position?: {
    bottom?: string;
    left?: string;
    right?: string;
    top?: string;
  };
  /** Source for card image. */
  src?: string;
  /** Title for card. */
  title?: string;
  /** A background color matching one of the allowed brand colors. */
  variant?:
    | 'coconut'
    | 'cornflower'
    | 'cucumber'
    | 'radish'
    | 'squash'
    | 'yam'
    | 'jasmine';
} & HTMLAttributes<HTMLDivElement>;

const ActionCard = React.forwardRef<HTMLDivElement, ActionCardProps>(
  function ActionCard(
    {
      alt,
      src,
      title,
      description,
      cta,
      position,
      isVertical,
      variant = 'cornflower',
    },
    forwardedRef,
  ) {
    const linkRef = useRef();

    return (
      <Card
        ref={forwardedRef}
        className={cx(stylesheet.root, { [stylesheet.isVertical]: isVertical })}
      >
        <CardImage
          appearance="basic"
          src={src}
          variant={variant}
          className={stylesheet.imgWrapper}
          position={position}
          alt={alt}
        />
        <div className={stylesheet.content}>
          <StackLayout gap={0}>
            <Text appearance="heading-6">{title}</Text>
            <Text
              appearance="medium-secondary"
              className={stylesheet.description}
            >
              {description}
            </Text>
            {cta &&
              React.cloneElement(cta, {
                ref: linkRef,
                className: cx(
                  {
                    [stylesheet.targetButton]: cta?.type === 'button',
                  },
                  stylesheet.targetLink,
                ),
              })}
          </StackLayout>
        </div>
      </Card>
    );
  },
);

export default ActionCard;

import React from 'react';
import cx from 'classnames';

import { CrossIcon } from '@mc/wink-icons';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';
import IconButton from '../IconButton';
import usePopover from './usePopover';

import stylesheet from './Popover.css';

export type PopoverCloseButtonProps = {
  children?: React.ReactNode;
  className?: string;
};

export default function PopoverCloseButton({ className, ...props }: PopoverCloseButtonProps) {
  const popover = usePopover();

  const handleClick = (popover as $TSFixMe).close;

  // Translation for default icon button label text
  const iconButtonLabelText = useDsTranslateMessage({
    id: 'mcds.popover_close_button.icon_button_label_text',
    defaultMessage: 'Close Dialog',
  });

  return (
    <IconButton
      className={cx(className, stylesheet.closeButton)}
      icon={<CrossIcon />}
      label={iconButtonLabelText}
      onClick={handleClick}
      {...props}
    />
  );
}

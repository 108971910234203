import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

// Translation for Calendar default values
export const TranslateCalendar = (values: $TSFixMe) => {
  const month = values && values.month;
  const year = values && values.year;
  return {
    sundayText: useDsTranslateMessage({
      id: 'mcds.calendar.sunday_text',
      defaultMessage: 'Sunday',
    }),
    mondayText: useDsTranslateMessage({
      id: 'mcds.calendar.monday_text',
      defaultMessage: 'Monday',
    }),
    tuesdayText: useDsTranslateMessage({
      id: 'mcds.calendar.tuesday_text',
      defaultMessage: 'Tuesday',
    }),
    wednesdayText: useDsTranslateMessage({
      id: 'mcds.calendar.wednesday_text',
      defaultMessage: 'Wednesday',
    }),
    thursdayText: useDsTranslateMessage({
      id: 'mcds.calendar.thursday_text',
      defaultMessage: 'Thursday',
    }),
    fridayText: useDsTranslateMessage({
      id: 'mcds.calendar.friday_text',
      defaultMessage: 'Friday',
    }),
    saturdayText: useDsTranslateMessage({
      id: 'mcds.calendar.saturday_text',
      defaultMessage: 'Saturday',
    }),
    januaryText: useDsTranslateMessage({
      id: 'mcds.calendar.january_text',
      defaultMessage: 'January',
    }),
    februaryText: useDsTranslateMessage({
      id: 'mcds.calendar.february_text',
      defaultMessage: 'February',
    }),
    marchText: useDsTranslateMessage({
      id: 'mcds.calendar.march_text',
      defaultMessage: 'March',
    }),
    aprilText: useDsTranslateMessage({
      id: 'mcds.calendar.april_text',
      defaultMessage: 'April',
    }),
    mayText: useDsTranslateMessage({
      id: 'mcds.calendar.may_text',
      defaultMessage: 'May',
    }),
    juneText: useDsTranslateMessage({
      id: 'mcds.calendar.june_text',
      defaultMessage: 'June',
    }),
    julyText: useDsTranslateMessage({
      id: 'mcds.calendar.july_text',
      defaultMessage: 'July',
    }),
    augustText: useDsTranslateMessage({
      id: 'mcds.calendar.august_text',
      defaultMessage: 'August',
    }),
    septemberText: useDsTranslateMessage({
      id: 'mcds.calendar.september_text',
      defaultMessage: 'September',
    }),
    octoberText: useDsTranslateMessage({
      id: 'mcds.calendar.october_text',
      defaultMessage: 'October',
    }),
    novemberText: useDsTranslateMessage({
      id: 'mcds.calendar.november_text',
      defaultMessage: 'November',
    }),
    decemberText: useDsTranslateMessage({
      id: 'mcds.calendar.december_text',
      defaultMessage: 'December',
    }),
    prevMonthText: useDsTranslateMessage({
      id: 'mcds.calendar.previous_month_text',
      defaultMessage: 'Previous month',
    }),
    nextMonthText: useDsTranslateMessage({
      id: 'mcds.calendar.next_month_text',
      defaultMessage: 'Next month',
    }),
    selectedText: useDsTranslateMessage({
      id: 'mcds.calendar.selected_text',
      defaultMessage: 'Selected date is',
    }),
    currentMonthText: useDsTranslateMessage({
      id: 'mcds.calendar.current_month_text',
      // Default uses template literals for spaces outside of the McIntlProvider which is needed for Anthology
      defaultMessage: `Current month is ${month} ${year}. Select a date.`,
      // Required for McIntlProvider to make translations in-app
      values: {
        month: month,
        year: year,
      },
    }),
  };
};

// A function to retrieve the day of the week.
export const TranslatedDays = () => {
  const {
    sundayText,
    mondayText,
    tuesdayText,
    wednesdayText,
    thursdayText,
    fridayText,
    saturdayText,
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
  } = TranslateCalendar();

  // Return as array
  return [
    sundayText,
    mondayText,
    tuesdayText,
    wednesdayText,
    thursdayText,
    fridayText,
    saturdayText,
  ];
};

// A function to retrieve the month of the year.
export const TranslatedMonths = () => {
  const {
    januaryText,
    februaryText,
    marchText,
    aprilText,
    mayText,
    juneText,
    julyText,
    augustText,
    septemberText,
    octoberText,
    novemberText,
    decemberText,
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
  } = TranslateCalendar();

  // Return as array
  return [
    januaryText,
    februaryText,
    marchText,
    aprilText,
    mayText,
    juneText,
    julyText,
    augustText,
    septemberText,
    octoberText,
    novemberText,
    decemberText,
  ];
};

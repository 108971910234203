import React from 'react';
import cx from 'classnames';
import useId from '@mc/hooks/useId';

import { ariaDescribedByIds } from '../utils';
import RadioGroup, {
  useRadioOnChange,
  useRadioSelectedValue,
  useRadioError,
} from './RadioGroup';

import stylesheet from './Radio.css';

export type RadioProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  hideLabel?: boolean;
  id?: string;
  label: React.ReactNode;
  value?: string | number | boolean;
};

const Radio = React.forwardRef<$TSFixMe, RadioProps>(function Radio(
  { children, hideLabel, id, label, value, disabled = false, ...props },
  forwardedRef,
) {
  const selectedValue = useRadioSelectedValue();
  /* For accessibility, error messages have better support when associated to form controls vs fieldset/legends.
   * Pulling in error id to associate it with aria-describedby.
   * Reference: https://russmaxdesign.github.io/accessible-error-fieldset/
   */
  // @ts-expect-error TS(2339) FIXME: Property 'errorId' does not exist on type 'Symbol'... Remove this comment to see the full error message
  const { errorId, error } = useRadioError();
  const onChange = useRadioOnChange();
  const inputId = useId();
  const descriptionId = useId();
  const describedBy = ariaDescribedByIds(
    children && descriptionId,
    error && errorId,
  );

  id = id || inputId;

  return (
    <div className={stylesheet.root}>
      <input
        className={stylesheet.input}
        id={id}
        type="radio"
        // @ts-expect-error TS(2322) FIXME: Type 'string | number | boolean | undefined' is no... Remove this comment to see the full error message
        value={value}
        // @ts-expect-error TS(2349) FIXME: This expression is not callable.
        onChange={() => onChange(value)}
        // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
        checked={selectedValue === value}
        {...props}
        ref={forwardedRef}
        aria-describedby={describedBy}
        disabled={disabled}
      />
      <div className={stylesheet.text}>
        <label
          htmlFor={id}
          className={cx(
            stylesheet.label,
            { [stylesheet.labelDisabled]: disabled },
            hideLabel && 'wink-visually-hidden',
          )}
        >
          {label}
        </label>
        {children && (
          <div id={descriptionId} className={stylesheet.description}>
            {children}
          </div>
        )}
      </div>
    </div>
  );
});

// Ensures the name is properly shown in storybook
Radio.displayName = 'Radio';

export { Radio as default, RadioGroup };

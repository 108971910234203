import React from 'react';
import FeedbackBlock from '../FeedbackBlock';

/**
 *
 * @deprecated Use the FeedbackBlock component from mc/wink instead.
 */
const PageAlert = (props: $TSFixMe) => {
  return <FeedbackBlock {...props} />;
};

export default PageAlert;

import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

// Translation for SearchInput default values
export const TranslateSearchInput = () => {
  return {
    searchMsg: useDsTranslateMessage({
      id: 'mcds.search_input.search',
      defaultMessage: 'Search...',
    }),
  };
};

import React from 'react';
import Toast from '../Toast';

/**
 *
 * @deprecated Use the Toast component from mc/wink instead.
 */
const Notification = (props: $TSFixMe) => {
  return <Toast {...props} />;
};

export default Notification;

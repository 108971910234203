import React from 'react';
import stylesheet from './LiveRegion.css';

export type LiveRegionProps = {
  /** False by default. Set to true if all items in the region need to be re-read upon updates. */
  'aria-atomic'?: boolean;
  /** Type of region to set priority with screen reader.
   * Assertive should be used sparingly and only if it's time-sensitive or critical.  */
  'aria-live'?: 'polite' | 'assertive';
  /** Message that needs to be announced by a screen reader. */
  children?: React.ReactNode;
  id?: string;
};

const LiveRegion = ({
  id,
  children,
  'aria-live': ariaLive = 'polite',
  'aria-atomic': ariaAtomic = false,
}: LiveRegionProps) => {
  return (
    <div
      className={stylesheet.root}
      id={id}
      role={ariaLive === 'assertive' ? 'alert' : 'status'}
      aria-live={ariaLive}
      /**
       * To-do: Explore scenarios to document when a region needs to be true.
       * Not ideal for a site controlled API with multiple items.
       */
      aria-atomic={ariaAtomic}
    >
      {children}
    </div>
  );
};

export default LiveRegion;

import React, { useContext } from 'react';
import cx from 'classnames';

// Import Theme styles
import './themes/apollo.css';

export const ThemeContext = React.createContext('light');
export const SubbrandContext = React.createContext('');

type ThemeProps = {
  /** The content inside theme. */
  children?: React.ReactNode;
  /** Change the color mode of the subbrand. */
  subbrand?: string;
  /** Change the color mode of the subbrand. */
  theme?: 'light' | 'dark';
};

const Theme = React.forwardRef<HTMLDivElement, ThemeProps>(function Theme(
  { theme = 'light', subbrand, children },
  forwardedRef,
) {
  return (
    <SubbrandContext.Provider value={subbrand ? subbrand : ''}>
      <ThemeContext.Provider value={theme}>
        <div className={cx(subbrand, theme)} ref={forwardedRef}>
          {children}
        </div>
      </ThemeContext.Provider>
    </SubbrandContext.Provider>
  );
});

const useThemeContext = () => useContext(ThemeContext);
const useSubbrandContext = () => useContext(SubbrandContext);

export default Theme;
export { useThemeContext, useSubbrandContext };
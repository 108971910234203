import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import stylesheet from './Heading.css';

export type HeadingProps = PropsWithChildren<{
  className?: string;
  /* The HTML heading element to render. Headings should be nested sequentially without skipping any levels. Learn more at https://www.w3.org/WAI/tutorials/page-structure/headings/. */
  level: 1 | 2 | 3 | 4 | 5 | 6;
  /* DEPRECATING: Please use size prop instead for new Heading mapping */
  appearance?:
    | 'heading-1'
    | 'heading-display'
    | 'heading-2'
    | 'heading-3'
    | 'heading-4'
    | 'heading-5'
    | 'heading-6';
  /* Change the visual appearance of the heading. */
  size?:
    | 'heading-1'
    | 'heading-2'
    | 'heading-3'
    | 'heading-4'
    | 'heading-5'
    | 'heading-6'
    | 'display-1'
    | 'display-2'
    | 'display-3'
    | 'display-4';
  textWrap?: 'wrap' | 'nowrap' | 'balance' | 'pretty';
}>;

type SupportedHeading = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

/**
 * Many screen reader users navigate by heading. Add headings for each section
 * of the page, even if they must be visually hidden — as a screen reader user,
 * I want to be able to navigate through each section easily.
 */
const Heading: React.ForwardRefRenderFunction<
  HTMLHeadingElement,
  HeadingProps
> = function (
  {
    children,
    className,
    level,
    appearance = `heading-${level}`,
    size = `heading-${level}`,
    textWrap,
    ...props
  },
  forwardedRef,
) {
  const H = `h${level}` as SupportedHeading;
  const isHeadingMappingFlagOn = mcdsFlagCheck('xp_mcds_redesign_heading_size');

  // MCDS Redesign heading sizes are much larger than existing when looking at them 1:1.
  // This maps our old headings to the equivalent redesign sizes. For reference: https://docs.google.com/document/d/1DLawBFOPELZGFvpldF_SwUKAkdZgqhvgZjzz4ZroKrU/edit?tab=t.0#heading=h.n44o0uvj9ftx
  // If deprecated appearance prop is used, map it to a lower visual size using the size prop to avoid scaling discrepancies.
  // This can be removed once the appearance prop is fully deprecated.
  if (appearance === 'heading-1') {
    size = 'heading-2';
  } else if (appearance === 'heading-2') {
    size = 'heading-3';
  } else if (appearance === 'heading-3') {
    size = 'heading-5';
  } else if (appearance === 'heading-4') {
    size = 'heading-6';
  } else if (appearance === 'heading-display') {
    size = 'heading-2';
  }
  // Otherwise all other headings are the same size.
  else {
    size = appearance;
  }

  return (
    <H
      className={cx(
        { [stylesheet[appearance]]: !isHeadingMappingFlagOn },
        className,
        stylesheet.root,
        textWrap && stylesheet[`text-${textWrap}`],
        { [stylesheet[size]]: isHeadingMappingFlagOn },
      )}
      ref={forwardedRef}
      {...props}
    >
      {children}
    </H>
  );
};

export default React.forwardRef(Heading);

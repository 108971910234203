import React, { PropsWithChildren } from 'react';

import { RedirectIcon } from '@mc/wink-icons';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

export type NewWindowLinkProps = PropsWithChildren<{
  /** The same URL string an `<a>` tag would expect. */
  href: string;
  /** Added to prevent phishing attacks by link target. */
  rel?: string;
}>;

const NewWindowLink = React.forwardRef<HTMLAnchorElement, NewWindowLinkProps>(
  function NewWindowLink(
    { href, rel = 'noopener noreferrer', children, ...props },
    forwardedRef,
  ) {
    // Translate default text
    const openWindowText = useDsTranslateMessage({
      id: 'mcds.new_window_link.open_text',
      defaultMessage: '(opens in new window)',
    });
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={href}
        target="_blank"
        rel={rel === '' ? undefined : rel}
        ref={forwardedRef}
        {...props}
      >
        {children}
        <RedirectIcon style={{ verticalAlign: 'middle', height: '1em' }} />
        <span className="wink-visually-hidden">{openWindowText}</span>
      </a>
    );
  },
);

export default NewWindowLink;

import React, { useRef, useLayoutEffect, AllHTMLAttributes } from 'react';
import cx from 'classnames';
import scrollIntoView from 'scroll-into-view-if-needed';

import { TickIcon } from '@mc/wink-icons';
import stylesheet from './ComboboxOption.css';

export type ComboboxOptionProps = {
  'aria-selected'?: boolean;
  children?: React.ReactNode;
  isHighlighted?: boolean;
  onSelect: (selectedValue?: string) => void;
  setActive: () => void;
  value: string;
} & AllHTMLAttributes<HTMLLIElement>;

const ComboboxOption = ({
  'aria-selected': ariaSelected,
  children,
  value,
  isHighlighted = false,
  setActive,
  onSelect,
  ...props
}: ComboboxOptionProps) => {
  const ref = useRef(null);
  useLayoutEffect(() => {
    if (isHighlighted && ref.current) {
      scrollIntoView(ref.current, {
        block: 'nearest',
        scrollMode: 'if-needed',
      });
    }
  }, [isHighlighted]);

  return (
    <li
      ref={ref}
      className={cx(stylesheet.option, {
        [stylesheet.isHighlighted]: isHighlighted,
      })}
      role="option"
      aria-selected={ariaSelected}
      onClick={() => onSelect(value)}
      // If options appear under the mouse, onMouseEnter will fire, but
      // onMouseMove will not. A subtle but effective usability improvement.
      onMouseMove={() => {
        setActive();
      }}
      {...props}
    >
      {children || value}
      {ariaSelected && <TickIcon className={stylesheet.selectedIcon} />}
    </li>
  );
};

export default ComboboxOption;

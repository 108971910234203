import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

// Translation for FileInput default values
export const TranslateFileInput = (values: $TSFixMe) => {
  return {
    errorMsg: useDsTranslateMessage({
      id: 'mcds.fileInput.error_text',
      defaultMessage: 'Error',
    }),
    errorHeadlineMsg: useDsTranslateMessage({
      id: 'mcds.fileInput.error_headline_text',
      defaultMessage: 'Max images reached',
    }),
    errorHeadlineMsgTwo: useDsTranslateMessage({
      id: 'mcds.fileInput.error_headline_text_two',
      defaultMessage: 'Oops an error occurred uploading your file!',
    }),
    errorReasonMsg: useDsTranslateMessage({
      id: 'mcds.fileInput.error_reason_text',
      defaultMessage: `You cannot upload more than ${values} files`,
      values: { maxFiles: values },
    }),
    errorReasonMsgTwo: useDsTranslateMessage({
      id: 'mcds.fileInput.error_reason_text_two',
      defaultMessage: 'Error Uploading Files',
    }),
    removeFileMsg: useDsTranslateMessage({
      id: 'mcds.fileInput.remove_file_text',
      defaultMessage: 'Remove file',
    }),
    duplicateFilesMsg: useDsTranslateMessage({
      id: 'mcds.fileInput.duplicate_files_text',
      defaultMessage: 'Duplicate Files',
    }),
    duplicateFilesReasonMsg: useDsTranslateMessage({
      id: 'mcds.fileInput.duplicate_files__reason_text',
      defaultMessage: 'You attempted to upload duplicates of',
    }),
    addFileMsg: useDsTranslateMessage({
      id: 'mcds.fileInput.add_file_text',
      defaultMessage: 'Add file',
    }),
    addFilesMsg: useDsTranslateMessage({
      id: 'mcds.fileInput.add_files_text',
      defaultMessage: 'Add files',
    }),
  };
};

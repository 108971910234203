import React, { useState, useEffect, Fragment, cloneElement } from 'react';

// Shared Components

import { UploadIcon } from '@mc/wink-icons';
import pluralize from '@mc/fn/pluralize';
import useId from '@mc/hooks/useId';
import ClusterLayout from '../ClusterLayout';
import FeedbackBlock from '../FeedbackBlock';
import StackLayout from '../StackLayout';
import Text from '../Text';

import { ariaDescribedByIds } from '../utils';
import { File } from './File';

import { TranslateFileInput } from './TranslateFileInput';
import stylesheet from './FileInput.css';

type ErrorMessageProps = {
  error?: string;
  errorHeadline?: string;
  errorReason?: string;
};

/**
 * Displays an error message whenever the user uploads the wrong file type
 * @param errorReason
 * @returns {JSX.Element}
 * @constructor
 */
const ErrorMessage = ({
  errorReason,
  errorHeadline,
  error,
}: ErrorMessageProps) => {
  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
  const { errorMsg } = TranslateFileInput();

  return (
    <FeedbackBlock variant="error" title={errorHeadline || errorMsg} inline>
      {errorReason || error}
    </FeedbackBlock>
  );
};

const defaultOnUpload = (files: $TSFixMe) => Promise.resolve(files);

export type FileInputProps = {
  allowedExtensions?: string[];
  children?: React.ReactNode;
  description?: string | React.ReactNode;
  error?: string;
  label: string;
  maxFiles?: number;
  onChange: $TSFixMeFunction;
  onDelete?: $TSFixMeFunction;
  onUpload?: $TSFixMeFunction;
  showImagePreviews?: boolean;
  value?: $TSFixMe[];
};

const FileInput = React.forwardRef<$TSFixMe, FileInputProps>(function FileInput(
  {
    description,
    label,
    error,
    onChange,
    onUpload = defaultOnUpload,
    onDelete,
    maxFiles = 1,
    allowedExtensions = [],
    value = [],
    children,
    showImagePreviews = false,
  },
  forwardedRef,
) {
  const id = useId();
  const descriptionId = useId();
  // Contains a set of file names
  const [newFiles, setNewFiles] = useState([]);
  const [errorState, setErrorState] = useState({
    isError: false,
    errorReason: '',
    errorHeadline: '',
  });

  // Translate default text
  const {
    errorHeadlineMsg,
    errorHeadlineMsgTwo,
    errorReasonMsg,
    errorReasonMsgTwo,
    duplicateFilesMsg,
    duplicateFilesReasonMsg,
    addFileMsg,
    addFilesMsg,
  } = TranslateFileInput(maxFiles);

  /**
   * Used to resolve the file name
   * @param {*} obj
   * @returns string
   */
  const resolveFileName = (file: $TSFixMe) => file.name;

  /**
   * This useEffect will run every time 'value' or 'newFiles' changes
   *
   * The purpose for this useEffect is to update the 'newFiles' list whenever
   * the 'value' property has been updated.
   *  If a file name inside of the 'newFiles' list appears inside of the 'value' list.
   *  Then remove that file name from the 'newFiles' list
   *
   * Context:
   * When the user selects a file, those files are immediately added to the 'newFiles' list.
   * That list is used to render File Cards with a loading Icon. When the file has completely
   * gone through the 'onUpload' process and kicks off the 'onChange' function. The 'value' prop
   * will get updated with those new files. The below function is used to clean up the 'newFiles'
   * list so that the rendering of the loading files is removed
   */
  useEffect(() => {
    if (newFiles.length) {
      // Creates a set of the existing file names
      const existingSet = new Set(value.map(resolveFileName));
      // Converts 'newFiles' into a set of file names
      const newSet = new Set(newFiles);

      /*
        Check if a file name in the 'newSet' exists within the
        'existingSet' if so then delete that file from the 'newSet'
       */
      for (const fileName of newSet) {
        if (existingSet.has(fileName)) {
          newSet.delete(fileName);
        }
      }

      /*
        If there's a difference in length between the 'newSet' and the
        'newFiles' list. Update the 'newFiles' list with 'newSet'
       */
      if (newSet.size !== newFiles.length) {
        setNewFiles(Array.from(newSet));
      }
    }
  }, [value, newFiles]);

  /**
   * HandleSelect is the function used to take files the user has selected,
   * run it through the 'onUpload' function, and then execute the 'onChange' function.
   * Before any of the above functions can be executed. There are some constraints that
   * are checked first.
   *
   * Constraints
   *  - Max files (defaults to 1)
   *  - No Duplicate files
   *
   *
   */
  const handleSelectFile = (fileList: $TSFixMe) => {
    // Prevents the user from uploading more files than the limit
    if (fileList.length + value.length > maxFiles) {
      setErrorState({
        isError: true,
        errorReason: errorReasonMsg,
        errorHeadline: errorHeadlineMsg,
      });
      return;
    }

    /**
     * Using the 'newFiles' list and the 'value' list.
     * A set is created and checked against to see if the user is trying to
     * add any duplicate files.
     */
    const fileNameSet = new Set([...value.map(resolveFileName), ...newFiles]);
    const duplicateFileSet = new Set();
    for (const { name } of fileList) {
      if (fileNameSet.has(name)) {
        duplicateFileSet.add(name);
      }
    }

    if (duplicateFileSet.size) {
      setErrorState({
        isError: true,
        errorReason: `${duplicateFilesReasonMsg} ${Array.from(
          duplicateFileSet,
        ).join(', ')}`,
        errorHeadline: duplicateFilesMsg,
      });
      return;
    }

    // If none of the above constraints aren't triggered reset error state
    setErrorState({ isError: false, errorReason: '', errorHeadline: '' });

    setNewFiles(fileList.map(resolveFileName));

    onUpload(fileList)
      .then((results: $TSFixMe) => {
        /**
         * 'onChange' is used to directly update the 'value' prop
         * So when new updates are returned you also need to add in the exsting value
         * into the array passed into the 'onChange' function
         */
        onChange([...value, ...results]);
      })
      .catch(() => {
        // handle error
        setErrorState({
          isError: true,
          errorReason: errorReasonMsgTwo,
          errorHeadline: errorHeadlineMsgTwo,
        });
        const fileListSet = new Set(fileList);
        setNewFiles(
          fileList.filter((file: $TSFixMe) => !fileListSet.has(file)),
        );
      });
  };

  /**
   * Removes a file from the list by its index
   * */
  const handleRemoveFile = (index: $TSFixMe) => {
    const resultFiles = value.slice();
    if (onDelete) {
      onDelete(resultFiles[index], index);
    }
    resultFiles.splice(index, 1);
    onChange(resultFiles);
  };

  /**
   * If there's a list of allowedExtensions they get converted into a string thats passed
   * into the inputs accept prop
   *
   * If allowedExtensions does not exist undefined is passed
   * */
  const acceptedFiles = allowedExtensions.length
    ? allowedExtensions
        .map((extension) => `.${extension.toLowerCase()}`)
        .join(',')
    : undefined;

  /**
   * Renders out the default file input in the event
   * the user hasn't passed in a child component
   * For readability this function was created
   * @returns [<File/>] list of File component
   */
  const renderDefaultFile = () =>
    value.map((file, index) => {
      return (
        <File
          key={index}
          file={file}
          onRemove={() => handleRemoveFile(index)}
          showImagePreviews={showImagePreviews}
        />
      );
    });

  /**
   * This function renders out the passed in Child component
   * The file object, the index, and the onRemove function are passed as props.
   * This ensures that values produced by the child component are in sync with the
   * array for the file input
   *
   * For readability this function was created
   * @returns Components
   */
  const renderChildComponent = () => {
    return value.map((file, index) => {
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      return cloneElement(children, {
        key: index,
        file,
        index,
        onRemove: () => handleRemoveFile(index),
      });
    });
  };

  return (
    <StackLayout className={stylesheet.containerWidth} ref={forwardedRef}>
      {/* Styled input control */}
      {((maxFiles > 1 && value.length < maxFiles) || value.length === 0) && (
        <Fragment>
          {errorState.isError || error ? (
            <ErrorMessage {...errorState} error={error} />
          ) : null}
          <ClusterLayout
            alignItems="flex-start"
            gap={8}
            nowrap={true}
            className={stylesheet.container}
          >
            <UploadIcon />
            <StackLayout gap={6}>
              <StackLayout gap={0}>
                <Text appearance="small-bold">{label}</Text>
                {typeof description === 'string' ? (
                  <Text appearance="small-secondary" id={descriptionId}>
                    {description}
                  </Text>
                ) : (
                  <div id={descriptionId}>{description}</div>
                )}
              </StackLayout>
              {/* Hidden file input */}
              <input
                className={stylesheet.fileinput}
                id={id}
                key={value && value.map(resolveFileName).join(';')}
                type="file"
                onChange={(e) => {
                  const fileList = e.target.files
                    ? Array.from(e.target.files)
                    : [];
                  handleSelectFile(fileList);
                }}
                aria-describedby={ariaDescribedByIds(
                  (error || description) && descriptionId,
                )}
                accept={acceptedFiles}
                multiple={maxFiles > 1}
              />
              <label htmlFor={id} className={stylesheet.upload}>
                {pluralize(addFileMsg, addFilesMsg, maxFiles)}
              </label>
            </StackLayout>
          </ClusterLayout>
        </Fragment>
      )}

      {/* Newly added Files that are loading */}
      {newFiles.map((fileName, index) => (
        <File
          key={index}
          file={{ name: fileName }}
          isLoading={true}
          showImagePreviews={showImagePreviews}
        />
      ))}

      {/* Uploaded file(s) */}
      {children ? renderChildComponent() : renderDefaultFile()}
    </StackLayout>
  );
});

export default FileInput;

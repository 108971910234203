import React from 'react';

const CheckboxDefault = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8C2 4.68629 4.68629 2 8 2H16C19.3137 2 22 4.68629 22 8V16C22 19.3137 19.3137 22 16 22H8C4.68629 22 2 19.3137 2 16V8Z"
        fill="#ffffff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3H8C5.23858 3 3 5.23858 3 8V16C3 18.7614 5.23858 21 8 21H16C18.7614 21 21 18.7614 21 16V8C21 5.23858 18.7614 3 16 3ZM8 2C4.68629 2 2 4.68629 2 8V16C2 19.3137 4.68629 22 8 22H16C19.3137 22 22 19.3137 22 16V8C22 4.68629 19.3137 2 16 2H8Z"
        fill="#859299"
      />
    </svg>
  );
};
const CheckboxChecked = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2" width="20" height="20" rx="6" fill="#017E89" />
      <path
        d="M17.8875 7.8751C17.7312 7.71887 17.5193 7.6311 17.2983 7.6311C17.0774 7.6311 16.8655 7.71887 16.7091 7.8751L10.2275 14.3568L7.28081 11.4109C7.12364 11.2592 6.91314 11.1752 6.69464 11.177C6.47614 11.1789 6.26714 11.2666 6.11263 11.4211C5.95812 11.5756 5.87048 11.7846 5.86858 12.0031C5.86668 12.2216 5.95068 12.4321 6.10248 12.5893L9.63831 16.1251C9.79458 16.2813 10.0065 16.3691 10.2275 16.3691C10.4485 16.3691 10.6604 16.2813 10.8166 16.1251L17.8875 9.05343C18.0437 8.89715 18.1315 8.68523 18.1315 8.46426C18.1315 8.2433 18.0437 8.03137 17.8875 7.8751Z"
        fill="#ffffff"
      />
    </svg>
  );
};
const CheckboxDisabled = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8C2 4.68629 4.68629 2 8 2H16C19.3137 2 22 4.68629 22 8V16C22 19.3137 19.3137 22 16 22H8C4.68629 22 2 19.3137 2 16V8Z"
        fill="#D5DEE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3H8C5.23858 3 3 5.23858 3 8V16C3 18.7614 5.23858 21 8 21H16C18.7614 21 21 18.7614 21 16V8C21 5.23858 18.7614 3 16 3ZM8 2C4.68629 2 2 4.68629 2 8V16C2 19.3137 4.68629 22 8 22H16C19.3137 22 22 19.3137 22 16V8C22 4.68629 19.3137 2 16 2H8Z"
        fill="#C3CED5"
      />
    </svg>
  );
};
const CheckboxDisabledChecked = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8C2 4.68629 4.68629 2 8 2H16C19.3137 2 22 4.68629 22 8V16C22 19.3137 19.3137 22 16 22H8C4.68629 22 2 19.3137 2 16V8Z"
        fill="#D5DEE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3H8C5.23858 3 3 5.23858 3 8V16C3 18.7614 5.23858 21 8 21H16C18.7614 21 21 18.7614 21 16V8C21 5.23858 18.7614 3 16 3ZM8 2C4.68629 2 2 4.68629 2 8V16C2 19.3137 4.68629 22 8 22H16C19.3137 22 22 19.3137 22 16V8C22 4.68629 19.3137 2 16 2H8Z"
        fill="#C3CED5"
      />
      <path
        d="M17.8875 7.8751C17.7312 7.71887 17.5193 7.6311 17.2983 7.6311C17.0774 7.6311 16.8655 7.71887 16.7091 7.8751L10.2275 14.3568L7.28081 11.4109C7.12364 11.2592 6.91314 11.1752 6.69464 11.177C6.47614 11.1789 6.26714 11.2666 6.11263 11.4211C5.95812 11.5756 5.87048 11.7846 5.86858 12.0031C5.86668 12.2216 5.95068 12.4321 6.10248 12.5893L9.63831 16.1251C9.79458 16.2813 10.0065 16.3691 10.2275 16.3691C10.4485 16.3691 10.6604 16.2813 10.8166 16.1251L17.8875 9.05343C18.0437 8.89715 18.1315 8.68523 18.1315 8.46426C18.1315 8.2433 18.0437 8.03137 17.8875 7.8751Z"
        fill="#ffffff"
      />
    </svg>
  );
};

export {
  CheckboxDefault,
  CheckboxChecked,
  CheckboxDisabled,
  CheckboxDisabledChecked,
};

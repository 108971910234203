import React from 'react';
import cx from 'classnames';

import {
  NotAllowedIcon,
  TickCircleIcon,
  WarningCircleIcon,
  InfoCircleIcon,
} from '@mc/wink-icons';
import ClusterLayout from '../ClusterLayout';
import Text from '../Text';

import stylesheet from './InlineFeedback.css';

export type InlineFeedbackProps = {
  /** Body copy */
  children?: React.ReactNode;
  className?: string;
  /** Title copy */
  headline: string;
  /** Feedback variants that also alter background color */
  type: 'success' | 'warning' | 'error' | 'info';
};

/**
 * @deprecated Use the FeedbackBlock component from mc/wink instead.
 *
 */
const InlineFeedback = React.forwardRef<HTMLDivElement, InlineFeedbackProps>(
  function InlineFeedback(
    { children, headline, type, className },
    forwardedRef,
  ) {
    const feedbackStyles = cx(
      stylesheet.inlineFeedback,
      stylesheet[type],
      className,
    );

    return (
      <ClusterLayout
        ref={forwardedRef}
        nowrap={true}
        className={feedbackStyles}
      >
        <div className={stylesheet.icon}>
          {type === 'success' && <TickCircleIcon />}
          {type === 'warning' && <WarningCircleIcon />}
          {type === 'error' && <NotAllowedIcon />}
          {type === 'info' && <InfoCircleIcon />}
        </div>

        <div>
          <Text appearance="medium-bold">{headline}</Text>
          {children && <Text appearance="medium">{children}</Text>}
        </div>
      </ClusterLayout>
    );
  },
);

export default InlineFeedback;

import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import stylesheet from './DotBadge.css';

export type DotBadgeProps = PropsWithChildren<{
  className?: string;
  hideLabel?: boolean;
  type?:
    | 'default'
    | 'active'
    | 'new'
    | 'success'
    | 'warning'
    | 'error'
    | 'info';
}>;

const DotBadge = React.forwardRef<HTMLSpanElement, DotBadgeProps>(
  function Badge({ className, children, type = 'default', hideLabel }, ref) {
    return (
      <span
        ref={ref}
        className={cx(stylesheet.root, stylesheet[type], className)}
      >
        <span className={stylesheet.dot} />
        <span className={cx({ 'wink-visually-hidden': hideLabel })}>
          {children}
        </span>
      </span>
    );
  },
);

export default DotBadge;

const useComboboxFilter = (
  options: string[],
  value: string,
  identity = (s: string) => s,
) => {
  const filteredOptions = options.map(identity).filter((option) => {
    return option.toLowerCase().includes(value.toLowerCase());
  });

  const exactMatch = filteredOptions.find(
    (option) => option.toLowerCase() === value.toLowerCase(),
  );

  return [filteredOptions, exactMatch];
};

export default useComboboxFilter;

import React from 'react';
import cx from 'classnames';
import ClusterLayout from '../ClusterLayout';
import Heading from '../Heading';
import Text from '../Text';
import stylesheet from './SectionHeader.css';

export type SectionHeaderProps = {
  /** Should be a button or link */
  actions?: React.ReactNode;
  /** Any custom content that will appear in the center of the bar. Please note that you will be responsible for handling any
   * mobile styles for these children.
   */
  children?: React.ReactNode;
  /**
   * Optional subtitle or description that appears below the horizontal line of the main title
   * */
  className?: string;
  description?: string;
  /** The main text of the Header. The string or node passed in here will be wrapped with a Heading component. */
  title: React.ReactNode;
};

/**
 * `SectionHeader` should  be used as a header within a section of the page. It is one level below from Pageheader.
 */
const SectionHeader = React.forwardRef<HTMLDivElement, SectionHeaderProps>(
  function SectionHeader(
    { children, title, actions, description, className },
    ref,
  ) {
    return (
      <React.Fragment>
        <div className={cx(stylesheet.header, className)} ref={ref}>
          <Heading level={2} appearance="heading-5">
            {title}
          </Heading>
          {children && <div>{children}</div>}

          {actions && (
            <ClusterLayout className={stylesheet.actions}>
              {actions}
            </ClusterLayout>
          )}
        </div>
        {description && (
          <Text
            appearance="medium-secondary"
            className={stylesheet.description}
          >
            {description}
          </Text>
        )}
      </React.Fragment>
    );
  },
);

export default SectionHeader;

import React from 'react';
import cx from 'classnames';

import { Heading } from '@mc/wink';
import ContentArea from '../ContentArea';
import StackLayout from '../StackLayout';
import stylesheet from './OverviewHero.css';

export type OverviewHeroProps = {
  actions?: React.ReactNode;
  bottom?: React.ReactNode;
  isBottomOverlap?: boolean;
  secondary?: string;
  title?: string;
  titleWidth?: 'normal' | 'narrow';
  top?: React.ReactNode;
  variant?: 'cornflower' | 'coconut' | 'cucumber' | 'radish' | 'squash' | 'yam';
};

function OverviewHero({
  isBottomOverlap = true,
  top = null,
  titleWidth = 'normal',
  variant = 'cucumber',
  bottom,
  title,
  secondary,
  actions,
}: OverviewHeroProps = {}) {
  return (
    <div
      className={cx(stylesheet.root, {
        [stylesheet[variant]]: variant,
        [stylesheet[titleWidth]]: titleWidth === 'narrow',
        [stylesheet.isBottomOverlap]: isBottomOverlap,
      })}
    >
      <ContentArea>
        <StackLayout gap={4}>
          {top && <div className={stylesheet.topWrapper}>{top}</div>}
          <Heading level={2} appearance="heading-display">
            {title}
          </Heading>
          <p className={stylesheet.secondary}>{secondary}</p>
          {actions && <div className={stylesheet.actions}>{actions}</div>}
          {bottom && <div className={stylesheet.bottom}>{bottom}</div>}
        </StackLayout>
      </ContentArea>
    </div>
  );
}

export default OverviewHero;

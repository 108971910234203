import React, { useState } from 'react';
import cx from 'classnames';
import { EyeHideIcon, EyeShowIcon } from '@mc/wink-icons';
import { Hide, Show } from '@design-systems/icons';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import Input from '../Input';
import Button from '../Button';
import { TranslateInput } from '../Input/TranslateInput';
import stylesheet from './InputPassword.css';

export type InputPasswordProps = {
  /** Will show in place of help text if defined also applies invalid style treatment. */
  error?: string;
  /** Will show in place of help text if defined also applies warning style treatment. */
  warning?: string;
  /** Text that appears below the input */
  helpText?: React.ReactNode;
  /** Visually hides the label provided by the `label` prop. */
  hideLabel?: boolean;
  /** The label of the input. */
  label?: React.ReactNode;
  /** Triggers when the input value is changed. This callback would usually handle updating the value prop. */
  onChange: (value: string) => void;
  /** The current value of the input. This component is uncontrolled so it is expected that a parent component will update this value when `onChange` is called. */
  value?: string;
  /** Adding className for the sake of testing the '.mcds-redesign-molecules' class in storybook*/
  className: string;
  /** Size of component, same as Input component */
  inputSize?: 'large' | 'medium';
  /** Defualt state of isHidden */
  showPassword?: boolean;
  /** Makes the input unusable and un-clickable. */
  disabled?: boolean;
};

/* A wrapper around the native browser `<input type="password" />`. Includes toggling the password asterisks. */
const InputPassword = React.forwardRef<HTMLInputElement, InputPasswordProps>(
  function InputPassword(
    {
      showPassword = false,
      error,
      warning,
      helpText,
      hideLabel,
      label,
      onChange,
      value,
      disabled,
      inputSize = 'large',
      ...props
    },
    forwardedRef,
  ) {
    const [isHidden, setIsHidden] = useState(!showPassword);

    const showHideButtonSize = inputSize === 'medium' ? 'small' : 'medium';

    // Translate default text
    const { showMsg, hideMsg } = TranslateInput();
    return (
      <>
        {mcdsFlagCheck('xp_mcds_redesign_components_molecules') ? (
          <Input
            error={error}
            warning={warning}
            helpText={helpText}
            hideLabel={hideLabel}
            label={label}
            onChange={onChange}
            value={value}
            ref={forwardedRef}
            type={isHidden ? 'password' : 'text'}
            disabled={disabled}
            inputSize={inputSize}
            {...props}
            miscText={
              <Button
                type="plain"
                className={stylesheet.showHideToggle}
                size={showHideButtonSize}
                onClick={() => setIsHidden((h) => !h)}
                disabled={disabled}
              >
                {isHidden ? <Show /> : <Hide />}
                {isHidden ? showMsg : hideMsg}
              </Button>
            }
          />
        ) : (
          <Input
            error={error}
            helpText={helpText}
            hideLabel={hideLabel}
            label={label}
            onChange={onChange}
            value={value}
            ref={forwardedRef}
            type={isHidden ? 'password' : 'text'}
            {...props}
            miscText={
              <button type="button" onClick={() => setIsHidden((h) => !h)}>
                <div className={stylesheet.visibilityToggle}>
                  {isHidden ? <EyeShowIcon /> : <EyeHideIcon />}
                  {isHidden ? showMsg : hideMsg}
                </div>
              </button>
            }
          />
        )}
      </>
    );
  },
);

export default InputPassword;

import React, { useRef } from 'react';
import cx from 'classnames';

import { PlusIcon } from '@mc/wink-icons';

import convertColors from '@mc/fn/convertColors';
import IconButton from '../IconButton';
import Text from '../Text';
import TextButton from '../TextButton';

import stylesheet from './Swatches.css';
import { TranslateSwatches } from './TranslateColorPicker';

const VALUE_TYPE = {
  HEX: 'hex',
  RGB: 'rgb',
};

export type SwatchesProps = {
  colorValueType?: string;
  currentRgbaValue?: $TSFixMe;
  hideLabel?: boolean;
  onChange?: $TSFixMeFunction;
  onSwatchesChange?: $TSFixMeFunction;
  rawHexValue?: string;
  swatches: (string | $TSFixMe)[];
};

const Swatches = React.forwardRef<$TSFixMe, SwatchesProps>(function Swatches(
  {
    colorValueType,
    swatches,
    onChange,
    onSwatchesChange,
    currentRgbaValue = {},
    rawHexValue,
    hideLabel = false,
  },
  forwardedRef,
) {
  const {
    r: currentRedValue,
    g: currentGreenValue,
    b: currentBlueValue,
    a: currentAlphaValue,
  } = currentRgbaValue;

  const editableSwatches = !!onSwatchesChange;

  const swatchGroupRef = useRef();
  const addToSwatchesRef = useRef();

  const {
    swatchesIconButtonLabelInitialText,
    swatchesIconButtonRgbLabelText,
    swatchesLabelText,
    swatchesIconButtonLabelEndText,
    swatchesTextButtonLabelText,
  } = TranslateSwatches({
    currentRedValue: currentRedValue,
    currentBlueValue: currentBlueValue,
    currentGreenValue: currentGreenValue,
  });

  return (
    // Wrapped with div instead of React fragment because it effects spacing in layout primitives.
    <div ref={forwardedRef}>
      <div className={stylesheet.header}>
        <div
          className={cx(
            'mcds-label-default',
            hideLabel && 'wink-visually-hidden',
          )}
        >
          {swatchesLabelText}
        </div>
        {editableSwatches && (
          <div className={stylesheet.addGroup}>
            <IconButton
              // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
              ref={addToSwatchesRef}
              className={stylesheet.addBtn}
              label={
                swatchesIconButtonLabelInitialText +
                (colorValueType === VALUE_TYPE.RGB
                  ? swatchesIconButtonRgbLabelText + currentAlphaValue * 100
                  : rawHexValue) +
                swatchesIconButtonLabelEndText
              }
              icon={<PlusIcon />}
              onClick={() => {
                onSwatchesChange((currentSwatches: $TSFixMe) => {
                  const newSwatch =
                    currentAlphaValue < 1 ? currentRgbaValue : rawHexValue;
                  return [newSwatch, ...currentSwatches];
                });
              }}
            />
          </div>
        )}
      </div>
      <div
        className={editableSwatches ? stylesheet.list : stylesheet.grid}
        // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message
        ref={swatchGroupRef}
      >
        {swatches.map((colorValue, i) => {
          // @ts-expect-error TS(2339) FIXME: Property 'rgb' does not exist on type 'Object'.
          const { rgb, rgbString, hex } = convertColors(colorValue);
          const isTransparent = rgb.a < 1;
          const swatchValue =
            colorValueType === VALUE_TYPE.RGB
              ? `${rgb.r}, ${rgb.g}, ${rgb.b}`
              : hex
                  // Outputs in #rrggbbaa. This removes alpha values.
                  .substring(0, 7)
                  .toUpperCase();
          return (
            <div key={i} className={stylesheet.item}>
              <button
                type="button"
                className={stylesheet.button}
                // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                onClick={() => onChange(rgbString)}
              >
                <span className={stylesheet.checkerBg}>
                  <span
                    className={cx(stylesheet.color, {
                      [stylesheet.isTransparent]: isTransparent,
                    })}
                    style={{
                      backgroundColor: isTransparent ? swatchValue : rgbString,
                    }}
                  />
                </span>
                {/* Hex/RGB value */}
                <span
                  className={cx(!editableSwatches && 'wink-visually-hidden')}
                >
                  {swatchValue}
                </span>
                {/* Alpha value */}
                {isTransparent && (
                  <Text
                    as="span"
                    appearance="small-secondary"
                    className={cx(!editableSwatches && 'wink-visually-hidden')}
                  >
                    {(rgb.a * 100).toFixed(0)}%
                  </Text>
                )}
              </button>
              {editableSwatches && (
                <TextButton
                  data-remove-swatch
                  className={stylesheet.itemRemoveBtn}
                  onClick={() => {
                    // Manage focus for remove buttons. This is tricky because
                    // the React key is index-based, so the swatches are not
                    // moved around, but are being modified in place.
                    const numSwatches = swatches.length;
                    if (numSwatches === 1) {
                      // This is the only swatch? Focus the "add" button.
                      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                      addToSwatchesRef.current.focus();
                    } else if (i === numSwatches - 1) {
                      // Is this the last swatch? Focus the previous button.
                      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                      const removeButtons = swatchGroupRef.current.querySelectorAll(
                        '[data-remove-swatch]',
                      );
                      removeButtons[i - 1].focus();
                    } else {
                      // Otherwise, do nothing--the `key` modifies elements in
                      // place so we never lose focus anyway.
                    }

                    // After managing focus, send messages.
                    onSwatchesChange(() => {
                      return swatches.filter((swatch, swatchIndex) => {
                        return swatchIndex !== i;
                      });
                    });
                  }}
                >
                  {swatchesTextButtonLabelText}
                  <span className="wink-visually-hidden">{swatchValue}</span>
                </TextButton>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
});

export default Swatches;

import React from 'react';
import cx from 'classnames';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import Button from '../Button';
import ActionList from '../ActionList';
import stylesheet from './ComboButton.css';

export type ComboButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  label: React.ReactNode;
  onActionListOpen?: $TSFixMeFunction;
  onClick?: $TSFixMeFunction;
  purpose?: 'standard' | 'passive';
  size?: 'small' | 'medium' | 'large';
  type?: 'primary' | 'secondary' | 'tertiary';
};

const ComboButton = React.forwardRef<$TSFixMe, ComboButtonProps>(
  function ComboButton(
    {
      // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
      className,
      children,
      disabled = false,
      isLoading = false,
      label,
      onClick,
      onActionListOpen,
      type = 'primary',
      size,
      purpose = 'standard',
      ...rest
    },
    forwardedRef,
  ) {
    const actionListLabelText = useDsTranslateMessage({
      id: 'mcds.combo_button.action_list_label_text',
      defaultMessage: 'Actions',
    });
    /* With the redesign buttons default to medium. Existing application has them default to large*/
    const isRedesignFlagOn = mcdsFlagCheck(
      'xp_mcds_redesign_components_molecules',
    );
    const sizeDefault = isRedesignFlagOn ? 'medium' : 'large';
    const comboButtonSize = size ? size : sizeDefault;
    return (
      <div
        className={cx(stylesheet.root, className, {
          [stylesheet.secondary]: type === 'secondary',
          [stylesheet[comboButtonSize]]: comboButtonSize,
        })}
      >
        <Button
          {...rest}
          size={comboButtonSize}
          type={type}
          ref={forwardedRef}
          className={cx(stylesheet.button, {
            [stylesheet[purpose]]: purpose !== 'standard',
            [stylesheet.secondary]: type === 'secondary',
          })}
          isLoading={isLoading}
          disabled={disabled || isLoading}
          onClick={onClick}
        >
          {label}
        </Button>
        <ActionList
          size={comboButtonSize}
          className={cx(stylesheet.actionList, {
            [stylesheet[purpose]]: purpose !== 'standard',
            [stylesheet.secondary]: type === 'secondary',
          })}
          disabled={isLoading || disabled}
          type={type}
          label={
            isRedesignFlagOn ? (
              <React.Fragment>
                <span className="wink-visually-hidden">
                  {actionListLabelText}
                </span>
              </React.Fragment>
            ) : (
              <React.Fragment>
                &nbsp;
                <span className="wink-visually-hidden">
                  {actionListLabelText}
                </span>
              </React.Fragment>
            )
          }
          onOpen={onActionListOpen}
          placement="bottom-end"
          data-testid="combobutton-expand-button"
        >
          {children}
        </ActionList>
      </div>
    );
  },
);

export default ComboButton;

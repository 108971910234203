import React from 'react';
import PropTypes from 'prop-types';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';
import stylesheet from './DetailList.css';

export type DetailListProps = {
  /** Should be a `DetailListItem` which will render a `dt` and `dd`. */
  children?: React.ReactNode;
};

const DetailList = React.forwardRef<HTMLDListElement, DetailListProps>(
  function DetailList({ children }, forwardedRef) {
    return (
      <dl className={stylesheet.root} ref={forwardedRef}>
        {children}
      </dl>
    );
  },
);

const DetailListItem = function DetailListItem({
  title,
  value,
}: {
  title: string;
  value: React.ReactNode;
}) {
  const emptyValueLabelText = useDsTranslateMessage({
    id: 'mcds.detail_list.empty_value_label_text',
    defaultMessage: 'Empty Value',
  });
  return (
    <>
      <dt className={stylesheet.title}>{title}</dt>
      <dd>
        {/* Add fallback text for screen reader when value is falsey. */}
        {!value ? (
          <>
            <span className="wink-visually-hidden">{emptyValueLabelText}</span>
            <span aria-hidden="true">--</span>
          </>
        ) : (
          value
        )}
      </dd>
    </>
  );
};

DetailListItem.propTypes = {
  /** Title that will be display in `dt`.  */
  title: PropTypes.string.isRequired,
  /** Title that will be display in `dd`.  */
  value: PropTypes.string,
};

export default DetailList;
export { DetailListItem };

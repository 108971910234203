import React from 'react';
import useId from '@mc/hooks/useId';
import ButtonOrLink from '../ButtonOrLink';
import { TranslatePage } from '../Page/TranslatePage';
import stylesheet from './PageHeaderBreadcrumbs.css';

export type PageHeaderBreadcrumbProps = {
  children: React.ReactNode;
  href: string;
  isCurrent?: boolean;
};

export const PageHeaderBreadcrumb = ({
  isCurrent,
  children,
  ...props
}: PageHeaderBreadcrumbProps) => {
  return (
    <ButtonOrLink
      {...props}
      className={stylesheet.breadcrumb}
      disabled={isCurrent}
    >
      {children}
    </ButtonOrLink>
  );
};

export type PageHeaderBreadcrumbsProps = {
  children: React.ReactNode;
};

const PageHeaderBreadcrumbs = ({ children }: PageHeaderBreadcrumbsProps) => {
  const id = useId();
  // Translate default text
  const { breadcrumbsText } = TranslatePage();
  return (
    <nav aria-labelledby={id} className={stylesheet.breadcrumbs}>
      <span id={id} className="wink-visually-hidden">
        {breadcrumbsText}
      </span>
      <ul>
        {React.Children.map(children, (child) => (
          <li
            aria-current={
              (child as React.ReactElement).props.isCurrent ? 'page' : undefined
            }
          >
            {child}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default PageHeaderBreadcrumbs;

import React, { useState } from 'react';

import { PlusIcon } from '@mc/wink-icons';
import cx from 'classnames';
import useId from '@mc/hooks/useId';
import ClusterLayout from '../ClusterLayout';
import Combobox, { ComboboxOption } from '../Combobox';
import Tag from '../Tag';
import TextButton from '../TextButton';
import { TranslateTagInput } from './TranslateTagInput';
import stylesheet from './TagInput.css';

export const TagOption = (props: $TSFixMe) => {
  return <ComboboxOption {...props} />;
};

const includes = (tags: $TSFixMe, filter = '') => {
  return tags
    .map((tag: $TSFixMe) => tag.toLowerCase())
    .includes(filter.toLowerCase());
};

const defaultOnFilter = (tags: $TSFixMe, value = '') => {
  return tags.filter((tag: $TSFixMe) =>
    tag.toLowerCase().includes(value.toLowerCase()),
  );
};

const defaultOnMap = (tags: $TSFixMe, selectedTags = []) => {
  const set = new Set([...tags, ...selectedTags]);
  return [...set].sort();
};

type SelectedTagsProps = {
  hideSelectedTags?: boolean;
  label: string;
  onRemove: $TSFixMeFunction;
  selectedTags: $TSFixMe[];
  selectedTagsLimit?: number;
};

const SelectedTags = ({
  hideSelectedTags = false,
  label,
  selectedTagsLimit,
  selectedTags,
  onRemove,
}: SelectedTagsProps) => {
  const labelId = useId();
  // If the limit isn't defined or a valid number, default allSelectedTagsVisible to true
  const [allSelectedTagsVisible, setAllSelectedTagsVisible] = useState(
    // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    isNaN(selectedTagsLimit),
  );

  const visibleTags = allSelectedTagsVisible
    ? selectedTags
    : selectedTags.slice(0, selectedTagsLimit);
  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const isOverLimit = selectedTags.length > selectedTagsLimit;
  const hiddenTagsCount = selectedTags.length - visibleTags.length;
  // Translate default text
  const {
    hiddenTagCountText,
    showFewerText,
    selectedTagText,
  } = TranslateTagInput({
    count: hiddenTagsCount,
  });
  if (visibleTags.length === 0) {
    return null;
  }
  return (
    <>
      {/* Selected tags are reached before the input. Announce the related label to screen readers. */}
      <span id={labelId} className="wink-visually-hidden">
        {label}
      </span>
      {/* Tags are in unordered list for screen readers to announce the number of selected tags 
      and navigate between lists. */}
      <ClusterLayout
        as="ul"
        gap={1}
        className={cx(
          stylesheet.selectedValues,
          hideSelectedTags ? 'wink-visually-hidden' : '',
        )}
        aria-labelledby={labelId}
      >
        {visibleTags.map((selected) => (
          <li key={selected}>
            <Tag
              appearance="dismissible"
              onClick={() => onRemove(selected)}
              size="small"
            >
              <span className="wink-visually-hidden">{selectedTagText}</span>
              {selected}
            </Tag>
          </li>
        ))}
        {isOverLimit && (
          <li>
            {hiddenTagsCount > 0 ? (
              <TextButton
                onClick={() => setAllSelectedTagsVisible(true)}
                className={stylesheet.selectedLimitToggle}
              >
                + {hiddenTagCountText}
              </TextButton>
            ) : (
              <TextButton
                onClick={() => setAllSelectedTagsVisible(false)}
                className={stylesheet.selectedLimitToggle}
              >
                {showFewerText}
              </TextButton>
            )}
          </li>
        )}
      </ClusterLayout>
    </>
  );
};

export type TagInputProps = {
  'aria-autocomplete'?: 'none' | 'list';
  'aria-labelledby'?: string;
  children: React.ReactNode;
  disabled?: boolean;
  error?: string;
  filterTags?: $TSFixMeFunction;
  helpText?: React.ReactNode;
  hideCreateLabel?: boolean;
  hideLabel?: boolean;
  hideSelectedTags?: boolean;
  label?: React.ReactNode;
  mapTags?: $TSFixMeFunction;
  miscText?: React.ReactNode;
  onChange: $TSFixMeFunction;
  onFocus?: $TSFixMeFunction;
  onInputChange?: $TSFixMeFunction;
  readOnly?: boolean;
  selectedTagsLimit?: number;
  value?: string[];
};

const TagInput = React.forwardRef<$TSFixMe, TagInputProps>(function TagInput(
  {
    'aria-autocomplete': ariaAutocomplete = 'list',
    value = [],
    onChange,
    onInputChange = () => {},
    filterTags = defaultOnFilter,
    mapTags = defaultOnMap,
    selectedTagsLimit,
    children,
    // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'TagIn... Remove this comment to see the full error message
    className,
    hideCreateLabel = false,
    hideSelectedTags = false,
    ...props
  },
  forwardedRef,
) {
  const [filter, setFilter] = useState('');
  // Translate default text
  const { createText } = TranslateTagInput({});

  const remove = (valueToRemove: $TSFixMe) => {
    onChange(value.filter((v) => v !== valueToRemove));
  };

  const toggle = (valueToToggle: $TSFixMe) => {
    if (valueToToggle.length > 0) {
      let nextValue;
      if (value.includes(valueToToggle)) {
        nextValue = value.filter((v) => v !== valueToToggle);
      } else {
        nextValue = [...value, valueToToggle];
      }
      onChange(nextValue);
    }
  };

  const handleInputChange = (filterValue: $TSFixMe) => {
    setFilter(filterValue);
    onInputChange(filterValue);
  };

  const filteredTags = filterTags(
    mapTags(
      (children as $TSFixMe).map((child: $TSFixMe) => child.props.value),
      value,
    ),
    filter,
  );

  return (
    <Combobox
      {...props}
      ref={forwardedRef}
      aria-autocomplete={ariaAutocomplete}
      aria-multiselectable={true}
      autohighlight
      value={filter}
      onChange={handleInputChange}
      onSelect={(selectedValue) => {
        toggle(selectedValue);
        handleInputChange('');
      }}
      closeOnSelect={false}
      // Refreshes Popup whenever value changes.
      unsafe_key={value.join('/')}
      // @ts-expect-error TS(2322) FIXME: Type '{ children: any[]; ref: ForwardedRef<any>; "... Remove this comment to see the full error message
      unsafe_renderExtraContent={
        <SelectedTags
          selectedTagsLimit={selectedTagsLimit}
          selectedTags={value}
          onRemove={remove}
          // Pass label so screen readers announce it when reaching list of selected tags
          // @ts-expect-error TS(2322) FIXME: Type 'ReactNode' is not assignable to type 'string... Remove this comment to see the full error message
          label={props.label}
          hideSelectedTags={hideSelectedTags}
        />
      }
    >
      {!hideCreateLabel &&
        filter.length > 0 &&
        !includes(filteredTags, filter) && (
          <TagOption key="create" value={filter}>
            <PlusIcon />
            &nbsp;{createText} "{filter}"
          </TagOption>
        )}
      {filteredTags.map((tag: $TSFixMe) => (
        <TagOption key={tag} value={tag} aria-selected={value.includes(tag)} />
      ))}
    </Combobox>
  );
});

export default TagInput;

import React from 'react';
import cx from 'classnames';
import { ChevronUp, ChevronDown } from '@design-systems/icons';
import stylesheet from './Stepper.css';

export type StepperProps = {
  value: number;
  step: number;
  inputSize?: 'large' | 'medium';
  disabled?: boolean;
  updateValue: (step: string) => void;
};

const Stepper = React.forwardRef<$TSFixMe, StepperProps>(function Stepper(
  { value, step = 1, inputSize = 'large', disabled = false, updateValue },
  forwardedRef,
) {
  const onIncrement = () => {
    if (Number.isInteger(value)) {
      updateValue(String(value + step));
    } else {
      updateValue(String(Math.ceil(value)));
    }
  };

  const onDecrement = () => {
    if (Number.isInteger(value)) {
      updateValue(String(value - step));
    } else {
      updateValue(String(Math.floor(value)));
    }
  };
  return (
    <div
      ref={forwardedRef}
      className={cx(stylesheet.stepper, stylesheet[inputSize])}
    >
      <button
        type="button"
        className={cx(stylesheet.stepperUp, {
          [stylesheet.disabled]: disabled,
        })}
        onClick={onIncrement}
        disabled={disabled}
      >
        <ChevronUp
          color={disabled ? 'var(--color-icon-disabled)' : undefined}
          size="xsmall"
        />
      </button>
      <button
        type="button"
        className={cx(stylesheet.stepperDown, {
          [stylesheet.disabled]: disabled,
        })}
        onClick={onDecrement}
        disabled={disabled}
      >
        <ChevronDown
          color={disabled ? 'var(--color-icon-disabled)' : undefined}
          size="xsmall"
        />
      </button>
    </div>
  );
});

export default Stepper;

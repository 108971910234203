import React from 'react';
import cx from 'classnames';

import { CrossIcon } from '@mc/wink-icons';
import { Close } from '@design-systems/icons';
import useId from '@mc/hooks/useId';
import { useDsTranslateMessage } from '@mc/wink/internationalization/useDsTranslateMessage';

import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import ButtonOrLink from '../ButtonOrLink';
import stylesheet from './Tag.css';

export type TagProps = {
  appearance?: 'dismissible';
  children: React.ReactNode;
  href?: string;
  onClick?: $TSFixMeFunction;
  size?: 'small' | 'large' | 'x-large';
  disabled?: boolean;
  className?: string;
};

const Tag = React.forwardRef<$TSFixMe, TagProps>(function Tag(
  {
    appearance,
    className,
    children,
    href,
    onClick,
    size = 'large',
    disabled = false,
    ...props
  },
  ref,
) {
  const isInteractive = onClick || href;
  const TagType = isInteractive ? ButtonOrLink : 'span';
  const removeId = useId();

  // Translation for default text
  const removeTagText = useDsTranslateMessage({
    id: 'mcds.tag.remove_tag_text',
    defaultMessage: 'Remove Tag',
  });

  const isRedesignFlagOn = mcdsFlagCheck(
    'xp_mcds_redesign_components_molecules',
  );

  return (
    <TagType
      ref={ref}
      className={cx(
        stylesheet.root,
        appearance === 'dismissible' && stylesheet.dismissible,
        isInteractive && stylesheet.interactive,
        stylesheet[size],
        isRedesignFlagOn && disabled ? stylesheet.disabled : '',
        className,
      )}
      onClick={onClick}
      href={href}
      aria-describedby={appearance === 'dismissible' ? removeId : undefined}
      {...props}
    >
      {children}
      {appearance === 'dismissible' && (
        <React.Fragment>
          {isRedesignFlagOn ? (
            <Close className={stylesheet.crossIcon} />
          ) : (
            <CrossIcon className={stylesheet.crossIcon} />
          )}
          <span hidden id={removeId}>
            {removeTagText}
          </span>
        </React.Fragment>
      )}
    </TagType>
  );
});

export default Tag;

import React from 'react';
import Slider from './Slider';

export type ChoiceSliderProps = {
  children: React.ReactNode;
  onChange: $TSFixMeFunction;
  value: $TSFixMe;
};

const ChoiceSlider = React.forwardRef<$TSFixMe, ChoiceSliderProps>(
  function ChoiceSlider({ onChange, value, children, ...props }, ref) {
    // const [numericValue, setNumericValue] = useState();
    const values: $TSFixMe = [];
    const cloned: $TSFixMe = [];
    let selectedIndex = 0;
    React.Children.forEach(children, (child, index) => {
      // retain the Tick's non-standard value prop then replace it with the array index so its compatible with Slider
      values.push((child as $TSFixMe).props.value);
      cloned.push(
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        React.cloneElement(child, {
          value: index,
          key: (child as $TSFixMe).props.value,
          choice: (child as $TSFixMe).props.value,
        }),
      );
      if (value === (child as $TSFixMe).props.value) {
        selectedIndex = index;
      }
    });

    return (
      <Slider
        onChange={(n) => onChange(values[n])}
        value={selectedIndex}
        ref={ref}
        {...props}
        aria-valuetext={value}
        max={values.length - 1}
        min={0}
        step={1}
        renderOutput={() => ''}
      >
        {cloned}
      </Slider>
    );
  },
);

export default ChoiceSlider;

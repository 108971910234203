import React, {
  useContext,
  createContext,
  useState,
  HTMLAttributes,
} from 'react';
import cx from 'classnames';
import { MenuDownIcon, MenuUpIcon } from '@mc/wink-icons';
import Checkbox from '../Checkbox';
import IconButton from '../IconButton';
import { TranslateSlat } from './TranslateSlat';
import stylesheet from './Slat.css';

// Provides typing for NestedContext.Provider
interface NestedContextType {
  isExpanded: boolean | null;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const NestedContext = createContext<NestedContextType>({
  isExpanded: false,
  setIsExpanded: () => {},
});

export type SlatsProps = {
  /** Consume `Slat` or `SlatGeneric` components */
  children: React.ReactNode;
  /** Optional CSS class */
  className?: string;
};

/* Slat Wrapper */
const Slats = ({ children, className, ...props }: SlatsProps) => {
  return (
    <ul className={className} {...props}>
      {children}
    </ul>
  );
};

export type SlatProps = {
  /** May consume `ComboButton`, `ActionList`, or `Button` for additional actions */
  action?: React.ReactElement;
  /** Consume `SlatContent` */
  children: React.ReactNode;
  /** Set on parent `Slat` to expand or collapse nested items. Defaults to collapsed. */
  isOpen?: boolean;
  /** The current value of the selected `Slat`. Used with `onSelect` */
  isSelected?: boolean;
  /** Triggers when the `Slat` is selected. */
  onSelect?: (value: boolean) => void;
} & HTMLAttributes<HTMLElement>;

/* Individual Slat */
const Slat = React.forwardRef<HTMLLIElement, SlatProps>(function Slat(
  {
    children,
    onSelect,
    className,
    action,
    isSelected,
    isOpen = false,
    ...props
  },
  forwardedRef,
) {
  const [isExpanded, setIsExpanded] = useState(isOpen);

  let nestedSlats;
  const filteredChildren: React.ReactNode[] = [];

  // Handle rendering of nested Slats
  React.Children.forEach(children, (child) => {
    if ((child as React.ReactElement).type === Slats) {
      nestedSlats = child;
    } else {
      filteredChildren.push(child);
    }
  });

  const classList = cx(
    stylesheet.root,
    {
      [stylesheet.interactive]: !!onSelect,
    },
    className,
  );

  const { checkBoxLabelText } = TranslateSlat();

  const initialContextValues = {
    isExpanded: nestedSlats ? isExpanded : null,
    setIsExpanded: setIsExpanded,
  };

  return (
    <NestedContext.Provider value={initialContextValues}>
      <li className={classList} ref={forwardedRef} {...props}>
        <div className={stylesheet.wrapper}>
          {!!onSelect && (
            <div className={stylesheet.selection}>
              <Checkbox
                hideLabel
                // TODO: Refactor to take children of SlatContent. This is too vague. aria-labelledby would work but this prop is required
                label={checkBoxLabelText}
                value={isSelected || false}
                onChange={onSelect}
              />
            </div>
          )}
          {filteredChildren}
          <div
            className={cx(
              action && action.props.icon
                ? stylesheet.iconAction
                : stylesheet.action,
            )}
          >
            {action && action}
          </div>
        </div>
        {/* If Slat is nested in another Slats wrapper */}
        {nestedSlats && (
          <div
            className={cx(stylesheet.child, {
              [stylesheet.expanded]: isExpanded,
            })}
          >
            {nestedSlats}
          </div>
        )}
      </li>
    </NestedContext.Provider>
  );
});

type SlatStatsProps = {
  /** Additional detail with `name` and `value` keys. Name will set the subtitle.*/
  stats?: {
    className?: string;
    name?: string;
    value?: string;
  }[];
};

const SlatStats = ({ stats }: SlatStatsProps) => {
  return (
    <div className={stylesheet.stats}>
      {stats?.map(({ name = '', value = '', className }) => (
        <dl key={name + value} className={className}>
          <dt>{name}</dt>
          <dd>{value}</dd>
        </dl>
      ))}
    </div>
  );
};

export type SlatContentProps = {
  /** Copy provided for individual `Slat` */
  children: React.ReactNode;
  /** Additional detail with `name` and `value` keys. Name will set the subtitle.*/
  stats?: {
    className?: string;
    name?: string;
    value?: string;
  }[];
  className?: string;
  /** Image src path for thumbnail preview */
  thumbnail?: string;
};

/* Content that lives inside of a Slat */
const SlatContent = ({
  children,
  stats,
  thumbnail,
  className = '',
}: SlatContentProps) => {
  const { isExpanded, setIsExpanded } = useContext(NestedContext);
  const { iconButtonCollapseLabelText, iconButtonExpandLabelText } =
    TranslateSlat();
  return (
    <React.Fragment>
      <div className={cx(stylesheet.content, className)}>
        <div>
          {thumbnail && (
            <img src={thumbnail} alt="" className={stylesheet.thumbnail} />
          )}
          {children}
        </div>
        {isExpanded !== null && (
          <div className={stylesheet.toggle}>
            <IconButton
              label={
                isExpanded
                  ? iconButtonCollapseLabelText
                  : iconButtonExpandLabelText
              }
              icon={isExpanded ? <MenuUpIcon /> : <MenuDownIcon />}
              onClick={() => setIsExpanded((last) => !last)}
              aria-expanded={isExpanded}
            />
          </div>
        )}
      </div>
      <SlatStats stats={stats} />
    </React.Fragment>
  );
};

export { Slat as default, Slats, SlatContent, NestedContext };

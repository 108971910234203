import React, { useCallback } from 'react';
import cx from 'classnames';
import useId from '@mc/hooks/useId';
import { ariaDescribedByIds } from '../utils';
import ChoiceCardGroup, {
  useChoiceOnChange,
  useChoiceSelectedValues,
  useChoiceInputTypeValue,
} from './ChoiceCardGroup';

import stylesheet from './ChoiceCard.css';

export type ChoiceCardProps = {
  children?: React.ReactNode;
  disabled?: boolean;
  id?: string;
  image?: string | React.ReactElement;
  label: React.ReactNode;
  value?: string | number | boolean;
};

/** Choice Card is a form element used when only one item can be selected from a group of two or more items in a list of options. */
const ChoiceCard = React.forwardRef<$TSFixMe, ChoiceCardProps>(function ChoiceCard(
  // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Props... Remove this comment to see the full error message
  { className, children, id, image, label, value, disabled = false, ...props },
  forwardedRef,
) {
  const selectedValues = useChoiceSelectedValues();
  const onChange = useChoiceOnChange();
  /* For accessibility, error messages have better support when associated to form controls vs fieldset/legends.
   * Pulling in error id to associate it with aria-describedby.
   * Reference: https://www.last-child.com/legend-aria-describedby/
   */
  // @ts-expect-error TS(2339) FIXME: Property 'inputType' does not exist on type 'Symbo... Remove this comment to see the full error message
  const { inputType, errorId, error } = useChoiceInputTypeValue();
  const inputId = useId();
  const labelId = useId();
  const descriptionId = useId();
  const describedBy = ariaDescribedByIds(
    children && descriptionId,
    error && errorId,
  );
  const [type, hidden] = inputType.split('-');
  id = id || inputId;

  const isSelected = useCallback(() => {
    return Array.isArray(selectedValues)
      ? selectedValues.includes(value)
      : // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
        selectedValues === value;
  }, [selectedValues, value]);

  return (
    <div
      className={cx(stylesheet.choice, className, {
        [stylesheet.checked]: isSelected(),
        [stylesheet.disabled]: disabled,
        [stylesheet.simple]: !!hidden,
      })}
    >
      <label htmlFor={id} className={stylesheet.card} {...props}>
        <input
          className={cx(stylesheet.input)}
          id={id}
          type={type}
          // @ts-expect-error TS(2322) FIXME: Type 'string | number | boolean | undefined' is no... Remove this comment to see the full error message
          value={value}
          checked={isSelected()}
          // @ts-expect-error TS(2349) FIXME: This expression is not callable.
          onChange={() => onChange(value)}
          disabled={disabled}
          ref={forwardedRef}
          aria-describedby={describedBy}
          aria-labelledby={labelId}
        />
        <div>
          {typeof image === 'string' ? (
            <img src={image} alt="" className={stylesheet.image}></img>
          ) : (
            image
          )}
        </div>
        <div
          className={hidden ? stylesheet.simpleLabel : stylesheet.label}
          id={labelId}
        >
          {label}
        </div>
        {children && (
          <div id={descriptionId} className={stylesheet.description}>
            {children}
          </div>
        )}
      </label>
    </div>
  );
});

export { ChoiceCard as default, ChoiceCardGroup };

import React, { createContext, useContext } from 'react';
import cx from 'classnames';
import useId from '@mc/hooks/useId';
import Fieldset from '../Fieldset';

import stylesheet from './ChoiceCard.css';
import stylesheetSelection from './SelectionChoiceCard.css';

const NO_PROVIDER = Symbol('NO_PROVIDER');

const OnChangeContext = createContext(NO_PROVIDER);
const SelectedValuesContext = createContext(NO_PROVIDER);
const InputTypeContext = createContext(NO_PROVIDER);

export type ChoiceCardGroupProps = {
  children: React.ReactNode;
  error?: string;
  horizontal?: boolean;
  label?: React.ReactNode;
  legend?: React.ReactNode;
  multiple?: boolean;
  onChange: $TSFixMeFunction;
  simple?: boolean;
  value?: string | number | boolean | $TSFixMe[];
};

/** Wrapper for Choice Card Buttons */
function ChoiceCardGroup({
  children,
  legend,
  label,
  error,
  horizontal = false,
  multiple = false,
  simple = false,
  value,
  onChange,
  // provided by a generic interface. We don't need to account
  // for PropTypes or usage, so we'll just disable linting here.
  // @ts-expect-error TS(2339) FIXME: Property 'miscText' does not exist on type 'Props'... Remove this comment to see the full error message
  // eslint-disable-next-line react/prop-types, no-unused-vars
  miscText,
  ...props
}: ChoiceCardGroupProps) {
  const errorId = useId();
  let inputType = multiple ? 'checkbox' : 'radio';
  if (simple) {
    inputType = inputType + '-hidden';
  }
  // For SelectionChoiceCard
  if (horizontal) {
    inputType = inputType + '-horizontal';
  }

  return (
    // @ts-expect-error TS(2322) FIXME: Type '$TSFixMeFunction' is not assignable to type ... Remove this comment to see the full error message
    <OnChangeContext.Provider value={onChange}>
      {/* @ts-expect-error TS(2322) FIXME: Type 'string | number | boolean | any[] | undefine... Remove this comment to see the full error message */}
      <SelectedValuesContext.Provider value={value}>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ inputType: string; errorId: string; error:... Remove this comment to see the full error message */}
        <InputTypeContext.Provider value={{ inputType, errorId, error }}>
          <Fieldset
            {...props}
            legend={label || legend}
            className={cx({
              [stylesheet.error]: !!error,
              [stylesheetSelection.error]: !!error,
            })}
          >
            {children}
            {/* Error message for ChoiceCard group */}
            {error && (
              <div id={errorId} className={stylesheet.errorMessage}>
                {error}
              </div>
            )}
          </Fieldset>
        </InputTypeContext.Provider>
      </SelectedValuesContext.Provider>
    </OnChangeContext.Provider>
  );
}

export function useChoiceOnChange() {
  const context = useContext(OnChangeContext);
  if (context === NO_PROVIDER) {
    throw new Error('<ChoiceCard>s must be wrapped by a <ChoiceCardGroup>.');
  }
  return context;
}

export function useChoiceSelectedValues() {
  const context = useContext(SelectedValuesContext);
  if (context === NO_PROVIDER) {
    throw new Error('<ChoiceCard>s must be wrapped by a <ChoiceCardGroup>.');
  }
  return context;
}

export function useChoiceInputTypeValue() {
  const context = useContext(InputTypeContext);
  if (context === NO_PROVIDER) {
    throw new Error('<ChoiceCard>s must be wrapped by a <ChoiceCardGroup>.');
  }
  return context;
}

export default ChoiceCardGroup;

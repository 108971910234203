import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { RgbaColorPicker } from 'react-colorful';
import { colord } from 'colord';

import convertColors from '@mc/fn/convertColors';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import StackLayout from '../StackLayout';
import Input from '../Input';
import InputNumber from '../InputNumber';
import SelectInline from '../Select/SelectInline';
import Option from '../Select/Option';
import Swatches from './Swatches';
import { TranslateColorPicker } from './TranslateColorPicker';

import stylesheet from './ColorPicker.css';
// react-colorful style overrides
import './CustomPicker.css';

const VALUE_TYPE = {
  HEX: 'hex',
  RGB: 'rgb',
};

const HEX_REGEX = /^#?([0-9a-zA-Z]{3}(?:[0-9a-zA-Z]{3})?)$/;

const isInRange = (value: $TSFixMe, min: $TSFixMe, max: $TSFixMe) => {
  const num = parseInt(value, 10);
  if (isNaN(num)) {
    return false;
  }

  return num >= min && num <= max;
};

const foldNaN = (value: $TSFixMe, fallback: $TSFixMe) => {
  if (isNaN(parseInt(value, 10))) {
    return fallback;
  }
  return value;
};

const clamp = (value: $TSFixMe, min: $TSFixMe, max: $TSFixMe) => {
  const num = parseInt(value, 10);
  if (isNaN(num)) {
    return min;
  }
  return Math.min(max, Math.max(num, min));
};

export type ColorPickerProps = {
  handleTransparent?: boolean;
  hideAlpha?: boolean;
  hideSwatches?: boolean;
  onChange: $TSFixMeFunction;
  onSwatchesChange?: $TSFixMeFunction;
  onValueTypeChange?: $TSFixMeFunction;
  showSelect?: boolean;
  swatches: (string | $TSFixMe)[];
  value?: string | $TSFixMe;
  valueType?: 'hex' | 'rgb';
};

const ColorPicker = React.forwardRef<$TSFixMe, ColorPickerProps>(
  function ColorPicker(
    {
      value = '#000000',
      swatches = [],
      showSelect = false,
      onChange,
      onSwatchesChange,
      valueType = 'hex',
      onValueTypeChange,
      hideAlpha = false,
      hideSwatches = false,
      handleTransparent = false,
    },
    forwardedRef,
  ) {
    // Convert color to object with all available format
    const color = convertColors(value.hex ?? value);
    const [colorValueType, setColorValueType] = useState(valueType);
    const {
      redValueLabelText,
      blueValueLabelText,
      greenValueLabelText,
      hexColorCodeLabelText,
      alphaLabelText,
      defaultColorValueTypeText,
      colorValueTypeLabelText,
      hexText,
      rgbText,
      rgbaText,
    } = TranslateColorPicker();
    // Extract RGBA/Hex values
    const rawHexValue = (color as $TSFixMe).hex;
    const currentHexValue = rawHexValue.replace(/^#/, '');
    const currentRgbaValue = (color as $TSFixMe).rgb;

    // Individual RGBA values
    const {
      r: currentRedValue,
      g: currentGreenValue,
      b: currentBlueValue,
      a: currentAlphaValue,
    } = currentRgbaValue;

    const [hexValue, setHexValue] = useState(currentHexValue);
    const [redValue, setRedValue] = useState(currentRedValue);
    const [greenValue, setGreenValue] = useState(currentGreenValue);
    const [blueValue, setBlueValue] = useState(currentBlueValue);
    const [alphaValue, setAlphaValue] = useState(
      (currentAlphaValue * 100).toFixed(0),
    );

    const hexInputRef = useRef(null);
    const redInputRef = useRef();
    const greenInputRef = useRef();
    const blueInputRef = useRef();
    const alphaInputRef = useRef();

    // Updates hex or rgb state when input is not focused.
    useEffect(() => {
      if (document.activeElement !== hexInputRef.current) {
        setHexValue(currentHexValue);
      }
      if (document.activeElement !== redInputRef.current) {
        setRedValue(currentRedValue.toString());
      }
      if (document.activeElement !== greenInputRef.current) {
        setGreenValue(currentGreenValue.toString());
      }
      if (document.activeElement !== blueInputRef.current) {
        setBlueValue(currentBlueValue.toString());
      }
      if (document.activeElement !== alphaInputRef.current) {
        setAlphaValue((currentAlphaValue * 100).toFixed(0));
      }
    }, [
      currentGreenValue,
      currentBlueValue,
      currentRedValue,
      currentAlphaValue,
      currentHexValue,
    ]);

    // Return object of all available formats for onChange color
    const handleChange = (data: $TSFixMe) => {
      onChange(convertColors(data));
    };

    const handleRedValue = (newRedVal: $TSFixMe) => {
      setRedValue(newRedVal);
      if (isInRange(newRedVal, 0, 255)) {
        handleChange({
          ...currentRgbaValue,
          r: newRedVal,
        });
      }
    };

    const handleGreenValue = (newGreenVal: $TSFixMe) => {
      setGreenValue(newGreenVal);
      if (isInRange(newGreenVal, 0, 255)) {
        handleChange({
          ...currentRgbaValue,
          g: newGreenVal,
        });
      }
    };

    const handleBlueValue = (newBlueValue: $TSFixMe) => {
      setBlueValue(newBlueValue);
      if (isInRange(newBlueValue, 0, 255)) {
        handleChange({
          ...currentRgbaValue,
          b: newBlueValue,
        });
      }
    };

    const handleAlphaValue = (newAlpha: $TSFixMe) => {
      setAlphaValue(newAlpha);
      if (isInRange(newAlpha, 0, 100)) {
        handleChange({
          ...currentRgbaValue,
          a: newAlpha / 100,
        });
      }
    };

    const handleHexValue = (newHex: $TSFixMe) => {
      setHexValue(newHex);
      if (HEX_REGEX.test(newHex)) {
        handleChange({
          ...colord(`#${newHex}`).toRgb(),
          ...(!handleTransparent && { a: currentAlphaValue }),
        });
      }
    };

    const handleValueTypeChange = (value_type: $TSFixMe) => {
      if (onValueTypeChange) {
        onValueTypeChange(value_type);
      }
      setColorValueType(value_type);
    };

    return (
      <div className={stylesheet.root} ref={forwardedRef}>
        <div className={cx('colorSpace', { hideAlpha: hideAlpha })}>
          <RgbaColorPicker
            color={{
              ...(color as $TSFixMe).rgb,
              /**
               * If alpha selection is hidden and the selected color is 100%
               * transparent, override the alpha value.
               *
               * This is needed by the email editor to support an always-present
               * transparent background option.
               */
              a:
                hideAlpha && (color as $TSFixMe).rgb.a === 0
                  ? 1
                  : (color as $TSFixMe).rgb.a,
            }}
            onChange={handleChange}
          />
        </div>

        <StackLayout gap={2} className={stylesheet.colorTypeSwitcher}>
          <div>
            {showSelect ? (
              <SelectInline
                className={stylesheet.label}
                value={colorValueType}
                placement="bottom-start"
                type="secondary"
                onChange={handleValueTypeChange}
                label={colorValueTypeLabelText}
                hideLabel
              >
                <Option value={VALUE_TYPE.HEX}>{hexText}</Option>
                <Option value={VALUE_TYPE.RGB}>
                  {!hideAlpha ? rgbaText : rgbText}
                </Option>
              </SelectInline>
            ) : (
              <span
                className="mcds-label-default"
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                onClick={() => hexInputRef.current.focus()}
              >
                {defaultColorValueTypeText}:
              </span>
            )}
          </div>
          <div className={cx(stylesheet.inputGroup)}>
            <div className={stylesheet.checkerBg}>
              <div
                className={stylesheet.colorPreview}
                style={{
                  backgroundColor: (color as $TSFixMe).rgbString,
                }}
              ></div>
            </div>
            {colorValueType === VALUE_TYPE.RGB ? (
              <div className={stylesheet.rgbContainer}>
                <InputNumber
                  value={redValue.toString()}
                  ref={redInputRef}
                  onChange={handleRedValue}
                  onBlur={() => {
                    // Clamp red value between 0 and 255.
                    const val = foldNaN(redValue, currentRedValue);
                    handleRedValue(String(clamp(val, 0, 255)));
                  }}
                  className={stylesheet.input}
                  maxLength={3}
                  hideLabel={true}
                  label={redValueLabelText}
                  {...(mcdsFlagCheck(
                    'xp_mcds_redesign_components_molecules',
                  ) && {
                    inputSize: 'medium',
                  })}
                />
                <InputNumber
                  value={greenValue.toString()}
                  ref={greenInputRef}
                  onChange={handleGreenValue}
                  onBlur={() => {
                    // Clamp green value between 0 and 255.
                    const val = foldNaN(greenValue, currentGreenValue);
                    handleGreenValue(clamp(val, 0, 255));
                  }}
                  className={stylesheet.input}
                  maxLength={3}
                  hideLabel={true}
                  label={greenValueLabelText}
                  {...(mcdsFlagCheck(
                    'xp_mcds_redesign_components_molecules',
                  ) && {
                    inputSize: 'medium',
                  })}
                />
                <InputNumber
                  value={blueValue.toString()}
                  ref={blueInputRef}
                  onChange={handleBlueValue}
                  onBlur={() => {
                    // Clamp blue value between 0 and 255.
                    const val = foldNaN(blueValue, currentBlueValue);
                    handleBlueValue(clamp(val, 0, 255));
                  }}
                  className={stylesheet.input}
                  maxLength={3}
                  hideLabel={true}
                  label={blueValueLabelText}
                  {...(mcdsFlagCheck(
                    'xp_mcds_redesign_components_molecules',
                  ) && {
                    inputSize: 'medium',
                  })}
                />
              </div>
            ) : (
              <Input
                // Outputs in #rrggbbaa. This removes alpha values.
                value={hexValue.substring(0, 6)}
                prefixText="#"
                ref={hexInputRef}
                onChange={handleHexValue}
                onBlur={() => {
                  // Changes hex to last-known value if input is invalid
                  setHexValue(currentHexValue);
                }}
                onPaste={(event: $TSFixMe) => {
                  const paste = event.clipboardData?.getData?.('text') || '';
                  // Strip hash from the start of the pasted text if applicable.
                  // Ensure the hex (minus the hash) matches the input maxLength.
                  if (
                    paste.startsWith('#') &&
                    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                    paste.length - 1 <= hexInputRef.current.maxLength
                  ) {
                    event.preventDefault();
                    handleHexValue(paste.replace(/^#/, ''));
                  }
                }}
                className={cx(stylesheet.input, stylesheet.inputHex)}
                maxLength={6}
                label={hexColorCodeLabelText}
                hideLabel={true}
                {...(mcdsFlagCheck('xp_mcds_redesign_components_molecules') && {
                  inputSize: 'medium',
                })}
              />
            )}
            {!hideAlpha && (
              <InputNumber
                value={alphaValue}
                suffixText="%"
                ref={alphaInputRef}
                onChange={handleAlphaValue}
                onBlur={() => {
                  // Clamp alpha value between 0 and 100.
                  const val = foldNaN(
                    alphaValue,
                    (currentAlphaValue * 100).toFixed(0),
                  );
                  handleAlphaValue(String(clamp(val, 0, 100)));
                }}
                className={cx(stylesheet.input, stylesheet.inputAlpha)}
                maxLength={3}
                label={alphaLabelText}
                hideLabel={true}
                {...(mcdsFlagCheck('xp_mcds_redesign_components_molecules') && {
                  inputSize: 'medium',
                })}
              />
            )}
          </div>
        </StackLayout>

        {!hideSwatches && (
          <Swatches
            onChange={handleChange}
            onSwatchesChange={onSwatchesChange}
            swatches={swatches}
            colorValueType={colorValueType}
            rawHexValue={rawHexValue}
            currentRgbaValue={currentRgbaValue}
          />
        )}
      </div>
    );
  },
);

export default ColorPicker;

import React, { AllHTMLAttributes } from 'react';
import useWinkBreakpoints from '@mc/hooks/useWinkBreakpoints';
import Button from '../Button';
import ActionList, { ActionListItem } from '../ActionList';
import IconButton from '../IconButton';
import { TranslatePage } from '../Page/TranslatePage';

// Sort children by the type, so that primary types are the first action
function sortChildrenByType(a: React.ReactElement, b: React.ReactElement) {
  if (a.props.type === b.props.type) {
    return 0;
  }

  if (a.props.type === 'primary') {
    return -1;
  }

  if (b.props.type === 'primary') {
    return 1;
  }

  if (a.props.type === 'secondary') {
    return -1;
  }

  if (b.props.type === 'secondary') {
    return 1;
  }

  return 0;
}

export type PageHeaderActionsProps = {
  children: React.ReactNode;
  currentPage?: string;
  label?: string;
};

function PageHeaderActions({
  children,
  label,
  ...props
}: PageHeaderActionsProps) {
  const { isPhone } = useWinkBreakpoints();

  // Translate default text
  const { actionsText } = TranslatePage();
  label = label || actionsText;
  return isPhone ? (
    <ActionList label={label} {...props} type="inline">
      {React.Children.toArray(children)
        .filter((child): child is React.ReactElement =>
          React.isValidElement(child),
        )
        .sort(sortChildrenByType)}
    </ActionList>
  ) : (
    children
  );
}

export type PageHeaderActionsItemProps = {
  children: React.ReactNode;
  icon?: React.ReactElement;
  isCurrent?: boolean;
  type?: 'primary' | 'secondary' | 'tertiary';
} & AllHTMLAttributes<HTMLButtonElement>;

export const PageHeaderActionsItem = React.forwardRef<
  HTMLElement,
  PageHeaderActionsItemProps
>(function PageHeaderActionsItem(props, forwardedRef) {
  const { isPhone } = useWinkBreakpoints();

  if (isPhone) {
    // @ts-expect-error TS(2322) FIXME: Type '{ type: undefined; ref: ForwardedRef<HTMLEle... Remove this comment to see the full error message
    return <ActionListItem {...props} type={undefined} ref={forwardedRef} />;
  }

  if (props.icon) {
    // @ts-expect-error TS(2322) FIXME: Type 'ReactNode' is not assignable to type 'string... Remove this comment to see the full error message
    return <IconButton {...props} label={props.children} ref={forwardedRef} />;
  }

  // @ts-expect-error TS(2322) FIXME: Type 'ForwardedRef<HTMLElement>' is not assignable... Remove this comment to see the full error message
  return <Button {...props} ref={forwardedRef} />;
});

export default PageHeaderActions;

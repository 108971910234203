import React, { HTMLAttributes } from 'react';
import cx from 'classnames';
import stylesheet from './Card.css';

export type CardProps = {
  /** Override the card's default border styles. */
  appearance?: 'default' | 'none';
  /** Children of card. */
  children: React.ReactNode;
  /** Reference for card link. */
  // eslint-disable-next-line react/no-unused-prop-types
  targetRef?: {
    current?: $TSFixMe;
  };
} & HTMLAttributes<HTMLDivElement>;

const Card = React.forwardRef<HTMLDivElement, CardProps>(function Card(
  { appearance = 'default', className, children },
  forwardedRef,
) {
  return (
    <div
      className={cx(stylesheet.root, className, stylesheet[appearance])}
      ref={forwardedRef}
    >
      {children}
    </div>
  );
});

export type CardImageProps = {
  /** Card image alt text. */
  alt?: string;
  /** Override the card image's default padding styles. */
  appearance?: 'basic' | 'default' | 'medium';
  /** Optional classes set for card image root element. */
  className?: string;
  /** Position placement for image. */
  position?: {
    bottom?: string;
    left?: string;
    right?: string;
    top?: string;
  };
  /** Handles sizing for image. */
  sizing?: 'fit' | 'fill';
  /** Source for card image. */
  src?: string;
  /** A background color matching one of the allowed brand colors. */
  variant?:
    | 'coconut'
    | 'cornflower'
    | 'cucumber'
    | 'radish'
    | 'squash'
    | 'yam'
    | 'jasmine';
} & HTMLAttributes<HTMLElement>;

const CardImage = React.forwardRef<HTMLDivElement, CardImageProps>(
  function CardImage(
    {
      src,
      variant,
      alt = '',
      sizing = 'fit',
      className,
      appearance = 'default',
      position,
    },
    forwardedRef,
  ) {
    return (
      // "fill" sizing will make the src a background image
      // TODO : Explore using img tag - https://jira.mailchimp.com/browse/DS-2340
      <>
        {sizing === 'fill' ? (
          <div
            role="img"
            aria-label={alt}
            style={{ backgroundImage: `url(${src})` }}
            className={cx(stylesheet.rootBgImage, className)}
            ref={forwardedRef}
          ></div>
        ) : (
          <figure
            className={cx(
              stylesheet.rootImage,
              className,
              variant ? stylesheet[variant] : undefined,
              stylesheet[appearance],
            )}
            ref={forwardedRef}
          >
            <img
              src={src}
              alt={alt}
              className={cx(stylesheet.img)}
              style={{
                position: position ? 'absolute' : 'relative',
                ...position,
              }}
            />
          </figure>
        )}
      </>
    );
  },
);

export default Card;
export { CardImage };

import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import {
  TriangleExclamationFill,
  CircleInfoFill,
  CircleClockFill,
  CircleExclamationFill,
  CircleCheckFill,
} from '@design-systems/icons';
import stylesheet from './IconBadge.css';

type FeedbackIconProps = {
  variant?: string;
  size?: 'small' | 'medium' | 'large';
};

const FeedbackIcon = ({ variant, size }: FeedbackIconProps) => {
  const icon =
    variant === 'success' ? (
      <CircleCheckFill size={size} />
    ) : variant === 'warning' ? (
      <CircleExclamationFill size={size} />
    ) : variant === 'error' ? (
      <TriangleExclamationFill size={size} />
    ) : variant === 'info' ? (
      <CircleInfoFill size={size} />
    ) : (
      <CircleClockFill size={size} />
    );
  return icon;
};

export type IconBadgeProps = PropsWithChildren<{
  type?: 'default' | 'success' | 'warning' | 'error' | 'info';
  hideLabel?: boolean;
}>;

const IconBadge = React.forwardRef<HTMLSpanElement, IconBadgeProps>(
  function IconBadge({ children, type = 'default', hideLabel }, ref) {
    return (
      <span className={cx(stylesheet.root, stylesheet[type])} ref={ref}>
        <span className={stylesheet.icon}>
          <FeedbackIcon variant={type} size="small" />
        </span>
        <span
          className={cx(stylesheet.label, {
            'wink-visually-hidden': hideLabel,
          })}
        >
          {children}
        </span>
      </span>
    );
  },
);

export default IconBadge;

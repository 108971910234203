import React, { AllHTMLAttributes, useContext, useRef } from 'react';
import cx from 'classnames';
import useUpdateEffect from '@mc/hooks/useUpdateEffect';
import TabContext, { TabContextType } from './TabContext';
import getTabId from './getTabId';
import stylesheet from './Tab.css';

export type TabProps = {
  index?: number;
  onClick?: () => void;
} & AllHTMLAttributes<HTMLSpanElement>;

function Tab({ index, onClick, ...props }: TabProps) {
  const ref = useRef<HTMLSpanElement>(null);
  const {
    id,
    currentIndex,
    setCurrentIndex,
    size,
    focusIndex,
    setFocusIndex,
    isManual,
    showOutline,
    setShowOutline,
  } = useContext(TabContext) as TabContextType;
  const tabId = getTabId(id, index);
  const isCurrentTab = currentIndex === index;
  const isFocused = focusIndex === index;

  const classList = cx(
    stylesheet.root,
    size ? stylesheet[size] : null,
    isManual && showOutline && stylesheet['focus-outline'],
  );

  useUpdateEffect(() => {
    if (isManual && isFocused && ref.current) {
      ref.current.focus();
    }

    if (!isManual && isCurrentTab && ref.current) {
      ref.current.focus();
    }
  }, [index, isCurrentTab, isFocused, isManual]);

  const handleClick = () => {
    if (isManual && setFocusIndex) {
      setFocusIndex(index ? index : 0);
    }

    if (onClick) {
      onClick();
    }

    if (typeof setCurrentIndex === 'function') {
      setCurrentIndex(index ? index : 0);
    }
    if (typeof setShowOutline === 'function') {
      setShowOutline(false);
    }
  };

  const getTabIndex = () => {
    const trackedIndex = isManual ? focusIndex : currentIndex;

    return trackedIndex === index ? 0 : -1;
  };

  return (
    <span
      className={classList}
      ref={ref}
      role="tab"
      id={tabId}
      tabIndex={getTabIndex()}
      aria-selected={isCurrentTab}
      aria-controls={tabId && `${tabId}:panel`}
      onClick={() => handleClick()}
      {...props}
    />
  );
}

export default Tab;

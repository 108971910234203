import React, { PropsWithChildren } from 'react';
import cx from 'classnames';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import stylesheet from './Badge.css';

export type BadgeProps = PropsWithChildren<{
  className?: string;
  /**
   * The different variants of a badge. The default state should be used for
   * incomplete processes and numbers that aren’t related to notifications. This
   * includes states such as “draft” and “unpublished.”
   */
  type?:
    | 'default'
    | 'active'
    | 'new'
    | 'success'
    | 'warning'
    | 'error'
    | 'info';
  priority?: 'primary' | 'secondary';
}>;

const Badge = React.forwardRef<HTMLSpanElement, BadgeProps>(function Badge(
  { className, children, type = 'default', priority },
  ref,
) {
  /* Our default priority changes with the app redesign to mirror IDS design spec */
  const isRedesignFlagOn = mcdsFlagCheck(
    'xp_mcds_redesign_components_molecules',
  );
  const defaultPriority = isRedesignFlagOn ? 'secondary' : 'primary';
  const badgePriority = priority || defaultPriority;

  return (
    <span
      className={cx(
        stylesheet.root,
        stylesheet[type],
        { [stylesheet[badgePriority]]: badgePriority === 'secondary' },
        className,
      )}
      ref={ref}
    >
      {children}
    </span>
  );
});

const BADGE_TYPES = [
  'default',
  'active',
  'new',
  'success',
  'warning',
  'error',
  'info',
];

export { BADGE_TYPES };
export default Badge;

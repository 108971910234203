import React from 'react';
import { getNestedValue, setNestedValue } from '@mc/fn/nestedValue';
import Form, {
  FormOnSubmitHandler,
  FormOnChangeHandler,
  FormSubmitResult,
} from '../Form';
import { useWizardActions, useWizardState } from './Wizard';

const defaultNextStep = ({
  currentStep,
  stepNames,
}: {
  currentStep: string;
  stepNames: string[];
}) => {
  const nextStep = stepNames[stepNames.indexOf(currentStep) + 1];
  return nextStep;
};

const noop = () => {};

export type WizardFormProps = {
  id?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Can either be a string that should match the name value of a WizardStep
   * OR a function that returns a name value of a WizardStep. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nextStep?: string | ((...args: any[]) => string);
  /** @ignore */
  onSubmit?:
    | FormOnSubmitHandler
    | ((data: object) => boolean | object)
    | (() => boolean);
};

function WizardForm({
  id,
  children,
  onSubmit,
  nextStep = defaultNextStep,
  ...props
}: WizardFormProps) {
  const wizardActions = useWizardActions();
  const wizardState = useWizardState();

  let _nextStep: string;
  if (typeof nextStep === 'string') {
    _nextStep = nextStep;
  } else if (typeof nextStep === 'function') {
    _nextStep = nextStep(wizardState);
  }

  const finishWizard = () => {
    // If there are no more next steps, we can mark the Wizard as finished.
    if (!_nextStep) {
      wizardActions.finish();
    }
  };

  const handleSubmit = async (data: object) => {
    const result = onSubmit ? await onSubmit(data) : noop();
    // Sync data to the wizard inventory if its valid form data (no errors
    // returned) and additional values are supplied. Once data is synced,
    // proceed to the next step, or if no more next steps exist, finish up.
    if (typeof result === 'object' && (result as FormSubmitResult).errors) {
      return result;
    }

    if (typeof result === 'object' && (result as FormSubmitResult).values) {
      wizardActions.setInventory((inventory: Record<string, unknown>) => {
        return {
          ...inventory,
          ...(result as FormSubmitResult).values,
        };
      }, finishWizard); // After inventory is saved, attempt to finish.
    } else {
      finishWizard();
    }

    if (_nextStep && result !== false) {
      wizardActions.navigate(_nextStep);
    }
  };

  // Sync all form data changes with the wizard inventory
  const handleChange: FormOnChangeHandler = (changed, values, name) => {
    wizardActions.setInventory((inventory: Record<string, unknown>) => {
      const value = getNestedValue(changed, name);
      const nextInventory = setNestedValue(inventory, name, value);
      return nextInventory;
    });
  };

  return (
    <Form
      {...props}
      id={id}
      initialValues={wizardState.inventory}
      // @ts-expect-error TS(2322) FIXME: Type '(data: $TSFixMe) => Promise<any>' is not ass... Remove this comment to see the full error message
      onSubmit={handleSubmit}
      // @ts-expect-error TS(2322) FIXME: Type '(changed: $TSFixMe, values: $TSFixMe, name: ... Remove this comment to see the full error message
      onChange={handleChange}
    >
      {children}
    </Form>
  );
}

export default WizardForm;

import React from 'react';
import cx from 'classnames';
import ContentArea from '../ContentArea';
import Heading from '../Heading';
import SwitcherLayout, { SwitcherLayoutProps } from '../SwitcherLayout';
import StackLayout from '../StackLayout';
import stylesheet from './Hero.css';

const DefaultLayout = (props: SwitcherLayoutProps) => {
  return <SwitcherLayout gap={8} {...props} />;
};

export type HeroProps = {
  /** The content of the hero. Most commonly it should be one or more `Text` components and a row of `Button`
   * components for calls to action. */
  children: React.ReactNode;
  /** Use this to customize the SwitcherLayout */
  layout?: typeof SwitcherLayout;
  /** This will normally be an image */
  secondary?: React.ReactNode;
  /** Most pages should use standard (which is the default). Use wide for full width sizes, use narrow for tightly constrained content. */
  size?: 'standard' | 'narrow' | 'wide';
  /** Wrap formatting for the heading */
  textWrap?: 'wrap' | 'nowrap' | 'balance' | 'pretty';
  /** This will be an h2 that has an h1 appearance. */
  title: React.ReactNode;
  /** A background color matching one of the allowed brand colors. */
  variant?:
    | 'coconut'
    | 'cornflower'
    | 'cucumber'
    | 'radish'
    | 'squash'
    | 'yam'
    | 'jasmine';
};

const Hero = React.forwardRef<HTMLDivElement, HeroProps>(function Hero(
  {
    variant,
    title,
    size,
    secondary,
    children,
    layout: Layout = DefaultLayout,
    textWrap,
  },
  forwardedRef,
) {
  return (
    <div
      className={cx(
        stylesheet.root,
        secondary && stylesheet.withSecondary,
        variant && stylesheet[variant],
      )}
      ref={forwardedRef}
    >
      <ContentArea size={size} className={stylesheet.content}>
        <Layout>
          <StackLayout gap={6} style={{ flexGrow: 2, alignSelf: 'center' }}>
            <Heading level={2} appearance="heading-display" textWrap={textWrap}>
              {title}
            </Heading>
            {children}
          </StackLayout>
          {secondary && (
            <div className={stylesheet.secondaryContent} style={{ order: 1 }}>
              {secondary}
            </div>
          )}
        </Layout>
      </ContentArea>
    </div>
  );
});

export default Hero;

import React from 'react';
import cx from 'classnames';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import Input from '../Input';
import stylesheet from './InputNumber.css';
import Stepper from './Stepper/Stepper';

export type InputNumberProps = {
  className?: string;
  helpText?: React.ReactNode;
  hideLabel?: boolean;
  inputMode?: 'numeric' | 'decimal' | 'tel';
  label?: React.ReactNode;
  maxLength?: number;
  minLength?: number;
  onChange: $TSFixMeFunction;
  pattern?: string;
  type?: 'text' | 'number';
  value: string;
  inputSize?: 'large' | 'medium';
  suffixText?: string;
  readOnly?: boolean;
  disabled?: boolean;
  onBlur?: $TSFixMeFunction;
};

const InputNumber = React.forwardRef<$TSFixMe, InputNumberProps>(
  function InputNumber(
    {
      className,
      inputMode = 'numeric',
      pattern = '[0-9]*',
      maxLength,
      minLength,
      type = 'text',
      readOnly = false,
      disabled = false,
      value,
      inputSize = 'large',
      suffixText,
      onChange,
      ...rest
    },
    forwardedRef,
  ) {
    const validateInputSize = ['large', 'medium'].includes(inputSize)
      ? inputSize
      : 'large';
    return (
      <>
        {mcdsFlagCheck('xp_mcds_redesign_components_molecules') ? (
          <Input
            {...rest}
            className={cx(stylesheet.inputNumber, className, {
              [stylesheet.disabled]: disabled,
            })}
            ref={forwardedRef}
            type={type}
            inputMode={inputMode}
            pattern={pattern}
            maxLength={maxLength}
            minLength={minLength}
            suffixText={
              type === 'number' && inputMode !== 'tel' && !readOnly ? (
                <Stepper
                  value={Number(value)}
                  step={1}
                  updateValue={onChange}
                  inputSize={
                    ['large', 'medium'].includes(inputSize)
                      ? inputSize
                      : 'large'
                  }
                  disabled={disabled}
                />
              ) : (
                suffixText
              )
            }
            value={String(value)}
            onChange={onChange}
            inputSize={validateInputSize}
            disabled={disabled}
            readOnly={readOnly}
          />
        ) : (
          <Input
            {...rest}
            className={cx(stylesheet.inputNumber, className)}
            ref={forwardedRef}
            type={type}
            inputMode={inputMode}
            pattern={pattern}
            maxLength={maxLength}
            minLength={minLength}
            suffixText={suffixText}
            value={value}
            onChange={onChange}
            inputSize={validateInputSize}
            disabled={disabled}
            readOnly={readOnly}
          />
        )}
      </>
    );
  },
);

export default InputNumber;

import React, {
  FocusEventHandler,
  KeyboardEvent,
  HTMLAttributes,
  useEffect,
  MouseEventHandler,
} from 'react';
import cx from 'classnames';

import { MenuDownIcon } from '@mc/wink-icons';
import {
  CircleExclamationFill,
  TriangleExclamationFill,
} from '@design-systems/icons';
import chainHandlers from '@mc/fn/chainHandlers';
import useId from '@mc/hooks/useId';
import { mcdsFlagCheck } from '@mc/wink/helpers/utils-ts';
import {
  formatError,
  ERROR_MUST_PROVIDE_LABEL,
  ariaDescribedByIds,
  ariaLabelledByIds,
} from '../utils';
import Listbox, { OptionProps as ListboxOptionProps } from '../Listbox';
import emulateSelectKeyboardSearch from './emulateSelectKeyboardSearch';
import stylesheet from './Select.css';
import { OptionProps } from './Option';

export type InputListboxTriggerProps = {
  filter?: string;
  id: string;
  isExpanded: boolean;
  onBlur: FocusEventHandler;
  onFilterChange: (val?: string) => void;
  // Unused; used for emulating keyboard search for non-inputs
  onHighlight?: (value: string | React.ReactNode) => void;
  onKeyDown: (e: KeyboardEvent) => void;
  // Unused; used for emulating keyboard search for non-inputs
  onSelect?: (selectedValue?: string | number) => void;
  onToggle: () => void;
  // Unused; used for emulating keyboard search for non-inputs
  options?: OptionProps[];
  placeholder?: React.ReactNode;
  renderSelectedValue?: (
    selected:
      | { children?: React.ReactNode; value: string }
      | { children?: React.ReactNode; value: string }[],
    placeholder: React.ReactNode,
  ) => string | readonly string[] | number | undefined;
  selected?: { children?: React.ReactNode; value: string }[];
} & HTMLAttributes<HTMLInputElement>;

const InputListboxTrigger = React.forwardRef<
  HTMLInputElement,
  InputListboxTriggerProps
>(
  (
    {
      filter,
      onFilterChange,
      onBlur,
      onToggle,
      id,
      selected = [],
      placeholder,
      renderSelectedValue,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      isExpanded,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      options,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onHighlight,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onSelect,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <input
        autoComplete="off"
        {...props}
        id={id}
        ref={forwardedRef}
        className={cx(stylesheet.trigger, stylesheet.inputTrigger)}
        placeholder={placeholder as string}
        value={
          filter === undefined ? renderSelectedValue?.(selected, '') : filter
        }
        onChange={(event) => {
          onFilterChange(event.target.value);
        }}
        onClick={() => {
          onToggle();
        }}
        onBlur={(event) => {
          onFilterChange(undefined);
          onBlur(event);
        }}
      />
    );
  },
);

export type SelectListboxTriggerProps = {
  // Filters are only used with inputs.
  filter?: string;
  isExpanded: boolean;
  onBlur: FocusEventHandler;
  // Filters are only used with inputs.
  onFilterChange?: (val?: string) => void;
  onHighlight: (value: string | React.ReactNode) => void;
  onKeyDown: (e: KeyboardEvent) => void;
  onSelect: (selectedValue?: string | number) => void;
  onToggle: () => void;
  options: (OptionProps & ListboxOptionProps)[];
  placeholder?: React.ReactNode;
  renderSelectedValue?: (
    selected:
      | { children?: React.ReactNode; value: string }
      | { children?: React.ReactNode; value: string }[],
    placeholder: React.ReactNode,
  ) => React.ReactNode;
  selected?: { children?: React.ReactNode; value: string }[];
} & HTMLAttributes<HTMLDivElement>;

const SelectListboxTrigger = React.forwardRef<
  HTMLDivElement,
  SelectListboxTriggerProps
>(
  (
    {
      selected = [],
      placeholder,
      renderSelectedValue,
      options,
      isExpanded,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      filter,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onFilterChange,
      onBlur,
      onKeyDown,
      onHighlight,
      onSelect,
      onToggle,
      ...props
    },
    forwardedRef,
  ) => {
    return (
      <div
        tabIndex={0}
        className={stylesheet.trigger}
        {...props}
        ref={forwardedRef}
        onBlur={onBlur}
        onKeyDown={chainHandlers(onKeyDown, (event: KeyboardEvent) => {
          emulateSelectKeyboardSearch(event, {
            options,
            isExpanded,
            onSelect,
            onHighlight,
            onToggle,
          });
        })}
        onClick={onToggle}
      >
        {renderSelectedValue
          ? renderSelectedValue(selected, placeholder)
          : null}
      </div>
    );
  },
);

export type SelectProps = {
  /** Pass an element's ID to include its text content as part of this component's accessible name. */
  'aria-labelledby'?: string;
  /** Link for the call to action
   *  This prop is only relevant in listbox mode
   */
  callToActionHref?: string;
  /** Label for the call to action at the bottom of the open menu
   *  This prop is only relevant in listbox mode
   */
  callToActionLabel?: string;
  /** Click handler for the call to action at the bottom of the open menu
   *  This prop is only relevant in listbox mode
   */
  callToActionOnClick?: MouseEventHandler<
    HTMLButtonElement | HTMLAnchorElement
  >;
  /** Should be children of the `Option` component */
  children: React.ReactNode;
  /** Makes the input unusable and un-clickable. */
  disabled?: boolean;
  /** Will show in place of help text if defined also applies invalid style treatment. */
  error?: string;
  /** Text that appears below the input */
  helpText?: React.ReactNode;
  /** Visually hides the label provided by the `label` prop. */
  hideLabel?: boolean;
  /** The label of the select. */
  label?: React.ReactNode;
  /** If true, sets the menu to be the width of it's target */
  matchTargetWidth?: boolean;
  /** Text that appears above the input and right of the label. Usually shows Required state of the input. */
  miscText?: React.ReactNode;
  /** Switches between the two modes:
   * Native: uses a native `<select>` including the native HTML options menu. This is the preferred mode for most Selects.
   * Listbox: Uses a custom implementation of an ARIA listbox. Useful for Selects that need complex options, such as
   * images or styling. Please avoid using listbox mode if your options are plain text.
   */
  mode?: 'native' | 'listbox';
  /** Enables a multiselect. Two important notes about this prop:
   * 1. multiselect will render "listbox" mode even if you have not chosen it in the mode prop (native multiple select is a subpar experience).
   * 2. The value will always be cast to an array onChange so ensure you intend to work with an array (the value can also be null).
   */
  multiple?: boolean;
  /** Triggers when the input value is changed. This callback would usually handle updating the value prop. */
  onChange: (
    updatedValue: string | number | (string | number | undefined)[],
  ) => void;
  /** Triggers when the object select is focused. */
  onFocus?: FocusEventHandler;
  /** A read-only input field cannot be modified (however, a user can tab to it, highlight it, and copy the text from it). */
  readOnly?: boolean;
  /** Override the default display of the selected value in the collapsed select.
   * Defaults to the children of the selected option for single selects, multi selects show a count of selected values. */
  renderSelectedValue?: (
    selected:
      | { children?: React.ReactNode; value: string }
      | { children?: React.ReactNode; value: string }[],
    placeholder: React.ReactNode,
  ) => React.ReactNode;
  /** When true, the select will be required to have a value, default will be false */
  required?: boolean;
  /** When true, the trigger for the Select will be an input and the value will act as a filter for the options */
  searchable?: boolean;
  /** Size of the select dropdown. Defaults to large */
  size?: 'small' | 'medium' | 'large';
  /** Warning message */
  warning?: string;
  /** The current value of the input. This component is uncontrolled so it is expected that a parent component will update this value when `onChange` is called. */
  value?: React.ReactNode | object;
  placeholder?: React.ReactNode;
} & HTMLAttributes<HTMLElement>;

type AdditionalTextProps = {
  state: 'error' | 'warning' | 'help';
  children: React.ReactNode;
};

const AdditionalText = ({ state, children }: AdditionalTextProps) => {
  return (
    <div className={cx(stylesheet.after)}>
      {state === 'error' ? (
        <TriangleExclamationFill />
      ) : state === 'warning' ? (
        <CircleExclamationFill />
      ) : null}
      <span className={stylesheet.message}>{children}</span>
    </div>
  );
};

const Select = React.forwardRef<HTMLElement, SelectProps>(function Select(
  {
    'aria-labelledby': ariaLabelledBy,
    callToActionHref,
    callToActionLabel,
    callToActionOnClick,
    className,
    children,
    mode = 'native',
    searchable = false,
    multiple = false,
    disabled = false,
    readOnly = false,
    hideLabel = false,
    helpText,
    error,
    label,
    onChange,
    matchTargetWidth = true,
    miscText,
    renderSelectedValue,
    required = false,
    size = 'large',
    placeholder,
    warning,
    ...props
  },
  forwardedRef,
) {
  useEffect(() => {
    if (!label && !ariaLabelledBy && __DEV__) {
      throw new Error(formatError(ERROR_MUST_PROVIDE_LABEL, 'Select'));
    }
  }, [label, ariaLabelledBy]);

  const id = useId();
  const labelId = useId();
  const helpTextId = useId();
  const miscTextId = useId();
  const describedBy = ariaDescribedByIds(
    (error || helpText) && helpTextId,
    miscText && miscTextId,
  );
  const redesigned = mcdsFlagCheck('xp_mcds_redesign_components_molecules');

  const isListbox = mode === 'listbox' || searchable || multiple;

  // We need to handle three cases:
  //
  // 1. Only pass a `label`. Native selects use a native label element, but
  //    Listbox isn't a native select so it must manually use `aria-labelledby`.
  // 2. Only pass an `aria-labelledby`. We don't render a label element.
  // 3. Pass both a `label` and `aria-labelledby`. We refer to both in the
  //    `aria-labelledby` attribute.
  const labelledBy = ariaLabelledByIds(
    ariaLabelledBy,
    (ariaLabelledBy || isListbox) && label && labelId,
  );

  return (
    <div
      className={cx(
        stylesheet.root,
        className,
        {
          [stylesheet[size]]: redesigned,
          [stylesheet.disabled]: redesigned && disabled,
        },
        error
          ? stylesheet.error
          : warning && redesigned
          ? stylesheet.warning
          : null,
      )}
    >
      <div className={stylesheet.before}>
        {redesigned && required && (
          // eslint-disable-next-line formatjs/no-literal-string-in-jsx
          <span className={stylesheet.required}>*</span>
        )}
        {label && (
          <label
            className={cx(
              'mcds-label-default',
              hideLabel && 'wink-visually-hidden',
            )}
            id={labelId}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        {miscText && (
          <span
            id={miscTextId}
            className={redesigned ? stylesheet.miscText : stylesheet.secondary}
          >
            {miscText}
          </span>
        )}
      </div>
      <div className={stylesheet.selectWrapper}>
        {isListbox ? (
          <Listbox
            callToActionOnClick={callToActionOnClick}
            callToActionHref={callToActionHref}
            callToActionLabel={callToActionLabel}
            matchTargetWidth={matchTargetWidth}
            multiple={multiple}
            // @ts-expect-error TS(2739) FIXME: Type 'ForwardRefExoticComponent<SelectInlineListbo... Remove this comment to see the full error message
            trigger={searchable ? InputListboxTrigger : SelectListboxTrigger}
            renderSelectedValue={renderSelectedValue}
            disabled={disabled}
            readOnly={readOnly}
            id={id}
            aria-labelledby={labelledBy}
            aria-describedby={describedBy}
            onChange={onChange}
            ref={forwardedRef}
            placeholder={placeholder}
            {...props}
          >
            {children}
          </Listbox>
        ) : (
          <select
            disabled={disabled}
            // @ts-expect-error TS(2322) FIXME: Type '{ children: ReactNode; _?: any; onFocus?: $T... Remove this comment to see the full error message
            readOnly={readOnly}
            id={id}
            aria-labelledby={labelledBy}
            aria-describedby={describedBy}
            onChange={(event) => onChange(event.target.value)}
            ref={forwardedRef as React.ForwardedRef<HTMLSelectElement>}
            placeholder={placeholder}
            {...props}
          >
            {children}
          </select>
        )}

        <div className={stylesheet.indicator}>
          <MenuDownIcon />
        </div>
      </div>
      {redesigned && (
        <AdditionalText state={error ? 'error' : warning ? 'warning' : 'help'}>
          {error || warning || helpText}
        </AdditionalText>
      )}
      {!redesigned &&
        (error ? (
          <div
            id={helpTextId}
            className={cx(stylesheet.after, stylesheet.errorMessage)}
          >
            {error}
          </div>
        ) : helpText ? (
          <div
            id={helpTextId}
            className={cx(stylesheet.after, stylesheet.secondary)}
          >
            {helpText}
          </div>
        ) : null)}
    </div>
  );
});

export default Select;

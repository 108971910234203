import React, { useState } from 'react';
import { MenuDownIcon } from '@mc/wink-icons';
import Animate from '../Animate';
import stylesheet from './Accordion.css';

export type AccordionItemProps = {
  /** Content inside the accordion */
  children: React.ReactNode;
  /** When true, the accordion item will be inititally expanded */
  defaultIsExpanded?: boolean;
  /** The text shown in the toggle button  */
  heading: string | React.ReactNode;
  /** A callback that triggers when the accordion expands/collapses. */
  onToggle?: (isExpanded: boolean) => void;
};

const AccordionItem = React.forwardRef<HTMLDivElement, AccordionItemProps>(
  function AccordionItem(
    { children, heading, onToggle = () => {}, defaultIsExpanded = false },
    forwardedRef,
  ) {
    const [isExpanded, setIsExpanded] = useState(defaultIsExpanded);
    return (
      <div className={stylesheet.item} ref={forwardedRef}>
        <button
          aria-expanded={isExpanded}
          type="button"
          className={stylesheet.heading}
          onClick={() => {
            onToggle(!isExpanded);
            setIsExpanded((value) => !value);
          }}
        >
          <MenuDownIcon />
          {heading}
        </button>
        <Animate
          animation="slide"
          easing="expressive"
          toggle={isExpanded}
          className={stylesheet.body}
        >
          {children}
        </Animate>
      </div>
    );
  },
);

export type AccordionProps = {
  /** Pass `AccordionItem` components */
  children?: React.ReactNode;
};

const Accordion = React.forwardRef<HTMLUListElement, AccordionProps>(
  function Accordion({ children }, forwardedRef) {
    return (
      // We explicitly set role="list" to accommodate VoiceOver, which hides list
      // semantics when `list-style` is reset.
      // eslint-disable-next-line jsx-a11y/no-redundant-roles
      <ul role="list" className={stylesheet.root} ref={forwardedRef}>
        {React.Children.map(children, (child) => {
          return child && <li className={stylesheet.listItem}>{child}</li>;
        })}
      </ul>
    );
  },
);

export default Accordion;
export { AccordionItem };

import React from 'react';
import cx from 'classnames';
import useId from '@mc/hooks/useId';
import { TranslateForm } from '../Form/TranslateForm';
import { ariaDescribedByIds } from '../utils';

import stylesheet from './Fieldset.css';

export type FieldsetProps = {
  /** Pass an element's ID to include its text content as
   * part of this component's accessible description,
   * alongside helpText. */
  'aria-describedby'?: string;
  /** Should contain one or more Radios. */
  children: React.ReactNode;
  /** Optional help text; becomes the fieldset's `aria-describedby`. */
  helpText?: React.ReactNode;
  /** Hide the legend visually, but keep it accessible. */
  hideLabel?: boolean;
  /** Passed to the legend of the fieldset */
  legend: React.ReactNode;
  /** Required label for groups of inputs like Radio or Checkbox */
  required?: boolean;
} & React.HTMLAttributes<HTMLFieldSetElement>;

/** Wrapper for the `<fieldset>` element along with data handling mechanisms. */
const Fieldset = React.forwardRef<HTMLFieldSetElement, FieldsetProps>(
  function Fieldset(
    {
      'aria-describedby': ariaDescribedBy,
      className,
      children,
      helpText,
      hideLabel,
      legend,
      required = false,
      ...props
    },
    forwardedRef,
  ) {
    const helpTextId = useId();
    const requiredId = useId();

    // Translate default text
    const { requiredText } = TranslateForm(0);

    // Filter out undefined values
    const ariaDescribedByIdsArray = (
      [ariaDescribedBy, helpText && helpTextId, required && requiredId].filter(
        Boolean,
      ) as string[]
    ).join(' ');

    return (
      <fieldset
        ref={forwardedRef}
        {...props}
        aria-describedby={ariaDescribedByIds(ariaDescribedByIdsArray)}
        className={cx(stylesheet.fieldset, className)}
      >
        <legend
          className={cx(
            'mcds-label-default',
            hideLabel && 'wink-visually-hidden',
          )}
        >
          {legend}
        </legend>
        {helpText && <div id={helpTextId}>{helpText}</div>}
        {required && (
          <div id={requiredId} className={stylesheet.required}>
            {requiredText}
          </div>
        )}

        {children}
      </fieldset>
    );
  },
);

export default Fieldset;

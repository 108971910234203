import React, {
  ClipboardEvent,
  FocusEvent,
  MouseEvent,
  KeyboardEvent,
  ChangeEvent,
  useState,
} from 'react';
import cx from 'classnames';
import { CircleXFill, Search } from '@design-systems/icons';
import useId from '@mc/hooks/useId';
import IconButton from '../IconButton';
import { TranslateSearchInput } from './TranslateSearchInput';
import stylesheet from './SearchInput.css';

export type SearchInputProps = {
  className?: string;
  /** Makes the input unusable and un-clickable. */
  disabled?: boolean;
  /** Visually hides the label provided by the `label` prop. */
  hideLabel?: boolean;
  /** An id to use for the input (this is typically set automatically) */
  id?: string;
  /** The label of the input. */
  label?: React.ReactNode;
  /** Triggered when the field no longer contains focus */
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  /** Triggers when the input value is changed. This callback would usually handle updating the value prop. */
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: MouseEvent<HTMLInputElement>) => void;
  /** Triggered when the field has focus */
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (event: ClipboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  /** The current value of the input. This component is uncontrolled so it is expected that a parent component will update this value when `onChange` is called. */
  value?: string;
  size?: string;
};

const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  function SearchInput(
    {
      className,
      disabled = false,
      hideLabel = true,
      id: idFromProps,
      label = 'Search',
      onBlur = () => {},
      onChange = () => {},
      onFocus = () => {},
      placeholder,
      size = 'large',
      value = '',
      ...props
    },
    forwardedRef,
  ) {
    const _id = useId();
    const labelId = useId();
    const id = idFromProps || _id;
    const [searchValue, setSearchValue] = useState(value);

    // Init translation
    const { searchMsg } = TranslateSearchInput();

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }
      setSearchValue(event.target.value);
    };

    const resetSearch = () => {
      setSearchValue('');
    };

    return (
      <div className={cx(stylesheet.root, className)}>
        <div>
          {label && (
            <label
              className={cx(
                'mcds-label-default',
                hideLabel && 'wink-visually-hidden',
              )}
              id={labelId}
              htmlFor={id}
            >
              {label}
            </label>
          )}
        </div>
        <div
          className={cx(stylesheet.searchInputWrapper, stylesheet[size], {
            [stylesheet.disabledSearchInput]: disabled,
          })}
        >
          <span className={stylesheet.searchInputPrefix} data-input-prefix>
            <Search size="xsmall" />
          </span>
          <input
            ref={forwardedRef}
            disabled={disabled}
            id={id}
            onChange={handleChange}
            onBlur={(e) => {
              onBlur(e);
            }}
            onFocus={(e) => {
              onFocus(e);
            }}
            placeholder={placeholder || searchMsg}
            value={searchValue}
            {...props}
          />
          {searchValue.length > 0 && (
            <IconButton
              label="Clear"
              data-testid="reset-icon"
              className={stylesheet.searchInputSuffix}
              data-input-suffix
              size="small"
              onClick={resetSearch}
              icon={<CircleXFill />}
            />
          )}
        </div>
      </div>
    );
  },
);

export default SearchInput;
